<i18n>
  {
    "es": {
      "FILL_YOUR_PHONE": "Rellena tu número de teléfono",
      "NECESSARY_FILL_PHONE": "Es necesario rellenar tu número de teléfono para cualquier inconveniente que pueda surgir con tu pedido.",
      "SAVING": "Guardando...",
      "SAVE": "Guardar",
      "REQUIRED_PHONE": "El teléfono es obligatorio",
      "TOO_SHORT_PHONE": "El teléfono es demasiado corto",
      "INVALID_PHONE": "El teléfono no es valido",
    },
    "en": {
      "FILL_YOUR_PHONE": "Fill in your phone number",
      "NECESSARY_FILL_PHONE": "It is necessary to fill in your phone number for any inconvenience that may arise with your order.",
      "SAVING": "Saving...",
      "SAVE": "Save",
      "REQUIRED_PHONE": "Phone is required",
      "TOO_SHORT_PHONE": "Phone is too short",
      "INVALID_PHONE": "Phone is not valid",
    },
    "fr": {
      "FILL_YOUR_PHONE": "Remplissez votre numéro de téléphone",
      "NECESSARY_FILL_PHONE": "Il est nécessaire de remplir votre numéro de téléphone pour tout inconvénient qui pourrait survenir avec votre commande.",
      "SAVING": "Enregistrement...",
      "SAVE": "Enregistrer",
      "REQUIRED_PHONE": "Le téléphone est obligatoire",
      "TOO_SHORT_PHONE": "Le téléphone est trop court",
      "INVALID_PHONE": "Le téléphone n'est pas valide",
    },
    "it": {
      "FILL_YOUR_PHONE": "Inserisci il tuo numero di telefono",
      "NECESSARY_FILL_PHONE": "È necessario inserire il tuo numero di telefono per eventuali inconvenienti che potrebbero sorgere con il tuo ordine.",
      "SAVING": "Salvataggio...",
      "SAVE": "Salva",
      "REQUIRED_PHONE": "Il telefono è obbligatorio",
      "TOO_SHORT_PHONE": "Il telefono è troppo corto",
      "INVALID_PHONE": "Il telefono non è valido",
    },
    "de": {
      "FILL_YOUR_PHONE": "Geben Sie Ihre Telefonnummer ein",
      "NECESSARY_FILL_PHONE": "Es ist erforderlich, Ihre Telefonnummer für eventuelle Unannehmlichkeiten, die bei Ihrer Bestellung auftreten können, auszufüllen.",
      "SAVING": "Speichern...",
      "SAVE": "Speichern",
      "REQUIRED_PHONE": "Telefon ist erforderlich",
      "TOO_SHORT_PHONE": "Telefon ist zu kurz",
      "INVALID_PHONE": "Telefon ist ungültig",
    },
    "pt": {
      "FILL_YOUR_PHONE": "Preencha o seu número de telefone",
      "NECESSARY_FILL_PHONE": "É necessário preencher o seu número de telefone para qualquer inconveniente que possa surgir com o seu pedido.",
      "SAVING": "Salvando...",
      "SAVE": "Salvar",
      "REQUIRED_PHONE": "Telefone é obrigatório",
      "TOO_SHORT_PHONE": "Telefone é muito curto",
      "INVALID_PHONE": "Telefone não é válido",
    },
  }
</i18n>

<template>
  <ModalCourtain
    :visibility="visibility"
    @close="emit('close')"
    :closeOnClickOutside="false"
  >
    <template v-slot:title> </template>
    <template v-slot:content>
      <div class="w-full h-fit md:pb-10 px-2">
        <div class="flex flex-col items-start">
          <span class="text-lg mt-1.5 text-sanremo-black font-semibold">{{
            t('FILL_YOUR_PHONE')
          }}</span>
          <span
            class="mt-1 mb-4 md:mb-5 text-sm text-gray-700 leading-5 tracking-wide"
            >{{ t('NECESSARY_FILL_PHONE') }}</span
          >
        </div>
        <div
          v-if="isLoading"
          class="relative h-14 isolate overflow-hidden before:border-t before:border-white/40 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/40 before:to-transparent"
        >
          <div class="w-full h-14 rounded-lg bg-[#d8d8d8]"></div>
        </div>
        <PhoneInput
          v-if="!isLoading"
          v-model="phone"
          :ref="phoneRef"
          :id="'phone-checkout'"
          :name="'phone-checkout'"
          :focus="true"
          @valid="handlePhoneValidation"
        />
        <button
          :disabled="!enabledButton"
          :class="{ 'opacity-50': !enabledButton }"
          @click="handleSubmit"
          class="uppercase w-full mt-4 md:mt-5 bg-others-black bg-opacity-90 text-white animation-get-light rounded h-14 font-bold text-sm transition-all duration-200 flex items-center justify-center"
        >
          <img
            v-if="saving"
            src="@/assets/svg/icons/icons-loading.svg"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          />

          <span v-if="saving">{{ t('SAVING') }}</span>
          <span v-else>{{ t('SAVE') }}</span>
        </button>
      </div>
    </template>
  </ModalCourtain>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

const props = defineProps({
  visibility: Boolean,
  saving: Boolean,
})

const emit = defineEmits(['close', 'ko', 'ok'])

const { addMessage, addFlashMessage } = useSnackBar()
const { t } = useI18n()

const isLoading = ref(true)
const phone = ref('')
const phoneRef = ref(null)
const phoneValid = ref(false)

onMounted(async () => {
  await nextTick() // wait for the next tick to ensure the component is mounted
  isLoading.value = false
})

const handlePhoneValidation = (isValid) => {
  phoneValid.value = isValid
}

const rules = computed(() => {
  return {
    phone: {
      required: helpers.withMessage(t('REQUIRED_PHONE'), required),
      isValid: helpers.withMessage(t('INVALID_PHONE'), () => phoneValid.value),
    },
  }
})

const v$ = useVuelidate(rules, {
  phone,
})

const enabledButton = computed(() => {
  if (!v$.value) return false
  v$.value.$validate()

  if (v$.value.$error || v$.value.$invalid) return false

  return true
})

const handleSubmit = async () => {
  v$.value.$validate()

  if (v$.value.$error) {
    const firstError = v$.value.$errors[0]
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: firstError.$message,
    })
    return
  }

  emit('update:phone', phone.value)
}
</script>
