<i18n>
  {
    "es": {
        "FINISH_ORDER": "Finalizar pedido",
        "ORDER_SUMMARY": "Resumen del pedido",
        "SOMETHING_GET_WRONG": "Algo ha ido mal, por favor, inténtalo de nuevo.",
        "ERROR": "Cupón no encontrado",
        "ERROR_ADDING_COUPON": "Cupón no encontrado o no válido.",
        "ADDED": "Añadido",
        "COUPON_ADDED": "Cupón añadido correctamente",
        "COUPON_REMOVED": "Cupón eliminado correctamente",
        "CREDITCARD_ADDED_SUCCEED" : "Tarjeta añadida correctamente",
        "BACK_ARROW": "Flecha hacia atrás",
        "ENABLE_POPUPS": "Por favor, habilita las ventanas emergentes para continuar con el pago.",
        "CUSTOMER_ABORT": "Operación cancelada por el cliente",
        "INVALID_DATA": "Datos inválidos",
        "TRANSACTION_DECLINED": "Transacción rechazada",
        "UNKNOWN_ERROR": "Ups, algo ha ido mal.",
    },
    "en": {
      "FINISH_ORDER": "Finish order",
      "ORDER_SUMMARY": "Order summary",
      "SOMETHING_GET_WRONG": "Something went wrong, please try again.",
      "ERROR": "Coupon not found",
      "ERROR_ADDING_COUPON": "Coupon not found or not valid.",
      "ADDED": "Added",
      "COUPON_ADDED": "Coupon added successfully",
      "COUPON_REMOVED": "Coupon removed successfully",
      "CREDITCARD_ADDED_SUCCEED" : "Card added successfully",
      "BACK_ARROW": "Back arrow",
      "ENABLE_POPUPS": "Please enable popups to continue with the payment.",
      "CUSTOMER_ABORT": "Operation cancelled by the customer",
      "INVALID_DATA": "Invalid data",
      "TRANSACTION_DECLINED": "Transaction declined",
      "UNKNOWN_ERROR": "Oops, something went wrong.",
    },
    "fr": {
      "FINISH_ORDER": "Terminer la commande",
      "ORDER_SUMMARY": "Résumé de la commande",
      "SOMETHING_GET_WRONG": "Quelque chose s'est mal passé, veuillez réessayer.",
      "ERROR": "Coupon introuvable",
      "ERROR_ADDING_COUPON": "Coupon introuvable ou non valide.",
      "ADDED": "Ajouté",
      "COUPON_ADDED": "Coupon ajouté avec succès",
      "COUPON_REMOVED": "Coupon supprimé avec succès",
      "CREDITCARD_ADDED_SUCCEED": "Carte ajoutée avec succès",
      "BACK_ARROW": "Flèche retour",
      "ENABLE_POPUPS": "Veuillez activer les fenêtres contextuelles pour continuer le paiement.",
      "CUSTOMER_ABORT": "Opération annulée par le client",
      "INVALID_DATA": "Données invalides",
      "TRANSACTION_DECLINED": "Transaction refusée",
      "UNKNOWN_ERROR": "Oups, quelque chose s'est mal passé."
    },
    "de": {
      "FINISH_ORDER": "Bestellung abschließen",
      "ORDER_SUMMARY": "Bestellübersicht",
      "SOMETHING_GET_WRONG": "Etwas ist schiefgelaufen, bitte versuche es erneut.",
      "ERROR": "Coupon nicht gefunden",
      "ERROR_ADDING_COUPON": "Coupon nicht gefunden oder nicht gültig.",
      "ADDED": "Hinzugefügt",
      "COUPON_ADDED": "Coupon erfolgreich hinzugefügt",
      "COUPON_REMOVED": "Coupon erfolgreich entfernt",
      "CREDITCARD_ADDED_SUCCEED": "Karte erfolgreich hinzugefügt",
      "BACK_ARROW": "Zurückpfeil",
      "ENABLE_POPUPS": "Bitte aktivieren Sie Pop-ups, um mit der Zahlung fortzufahren.",
      "CUSTOMER_ABORT": "Aktion vom Kunden abgebrochen",
      "INVALID_DATA": "Ungültige Daten",
      "TRANSACTION_DECLINED": "Transaktion abgelehnt",
      "UNKNOWN_ERROR": "Ups, etwas ist schiefgelaufen."
    },
    "it": {
      "FINISH_ORDER": "Completa l'ordine",
      "ORDER_SUMMARY": "Riepilogo ordine",
      "SOMETHING_GET_WRONG": "Qualcosa è andato storto, per favore riprova.",
      "ERROR": "Coupon non trovato",
      "ERROR_ADDING_COUPON": "Coupon non trovato o non valido.",
      "ADDED": "Aggiunto",
      "COUPON_ADDED": "Coupon aggiunto con successo",
      "COUPON_REMOVED": "Coupon rimosso con successo",
      "CREDITCARD_ADDED_SUCCEED": "Carta aggiunta con successo",
      "BACK_ARROW": "Freccia indietro",
      "ENABLE_POPUPS": "Per favore, abilita i pop-up per continuare con il pagamento.",
      "CUSTOMER_ABORT": "Operazione annullata dal cliente",
      "INVALID_DATA": "Dati non validi",
      "TRANSACTION_DECLINED": "Transazione rifiutata",
      "UNKNOWN_ERROR": "Oops, qualcosa è andato storto."
    },
    "pt": {
      "FINISH_ORDER": "Finalizar pedido",
      "ORDER_SUMMARY": "Resumo do pedido",
      "SOMETHING_GET_WRONG": "Algo deu errado, por favor, tente novamente.",
      "ERROR": "Cupom não encontrado",
      "ERROR_ADDING_COUPON": "Cupom não encontrado ou não válido.",
      "ADDED": "Adicionado",
      "COUPON_ADDED": "Cupom adicionado com sucesso",
      "COUPON_REMOVED": "Cupom removido com sucesso",
      "CREDITCARD_ADDED_SUCCEED": "Cartão adicionado com sucesso",
      "BACK_ARROW": "Seta para trás",
      "ENABLE_POPUPS": "Por favor, habilite os pop-ups para continuar com o pagamento.",
      "CUSTOMER_ABORT": "Operação cancelada pelo cliente",
      "INVALID_DATA": "Dados inválidos",
      "TRANSACTION_DECLINED": "Transação recusada",
      "UNKNOWN_ERROR": "Ops, algo deu errado."
    }
}
</i18n>

<template>
  <div>
    <div class="p-4">
      <div
        class="md:max-w-xl mx-auto w-full mdplus:min-h-imgmdplus mdplus:min-w-imgmdplus"
      >
        <div class="flex justify-start items-center pb-4">
          <NuxtLink :to="`/${locale}/account`">
            <img
              src="@/assets/svg/icons/icons-arrow-left.svg"
              class="h-5 w-5"
              :alt="t('BACK_ARROW')"
            />
          </NuxtLink>
          <h2
            class="mx-auto font-prata text-xl md:text-28 font-black tracking-wider"
          >
            {{ t('FINISH_ORDER') }}
          </h2>
        </div>
        <div class="h-px bg-neutral-light-grey-2"></div>

        <div>
          <div class="pt-8">
            <CheckoutCollapseProducts
              :data="checkout?.shipments"
              :shipmentSelected="selectedShipment"
              :loading="loading"
              :total="
                checkout?.totals?.find((total) => {
                  return total.type == 'total'
                }).value
              "
              :currency="checkout?.currency"
            />
          </div>

          <!-- DESHABILITADOS POR AHORA -->
          <!-- <CheckoutShipmentsSwitch
            :joinShipments="checkout?.join_shipments"
            @update:joinShipments="handleJoinShipments"
          /> -->

          <!-- <CheckoutShipmentsSelect
            :shipments="checkout?.shipments"
            :shipmentSelected="selectedShipment"
            @update:shipmentSelected="selectedShipment = $event"
          /> -->

          <div class="mt-8 h-px bg-neutral-light-grey-2"></div>

          <div
            v-if="loading || isLoading || !checkout"
            class="w-full pt-10 mt-1"
          >
            <div class="w-full flex justify-between items-center">
              <div
                class="h-4 md:h-5 w-5/12 md:w-3/12 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="h-3 w-5/12 md:w-3/12 md:mt-1 rounded-lg bg-[#d8d8d8]"
              ></div>
            </div>

            <div class="mt-1 pt-5 flex justify-start items-center gap-x-4">
              <div class="h-14 w-1/2 md:w-4/12 rounded bg-[#d8d8d8]"></div>
              <div class="h-14 w-1/2 md:w-4/12 rounded bg-[#d8d8d8]"></div>
            </div>

            <div
              v-if="isMobileOrTablet"
              class="mt-12 md:mt-10 flex items-center justify-center space-x-8 pl-4"
            >
              <div class="h-32 w-8/12 rounded-lg bg-[#d8d8d8]"></div>
              <div class="h-32 w-3/12 rounded-l-lg bg-[#d8d8d8]"></div>
            </div>

            <div
              v-if="!isMobileOrTablet"
              class="mt-1 flex justify-center items-center space-x-6 pl-4 pr-2"
            >
              <div
                class="mt-20 md:mt-10 h-32 w-[41.5%] rounded bg-[#d8d8d8]"
              ></div>
              <div
                class="mt-20 md:mt-10 h-32 w-[41.5%] rounded bg-[#d8d8d8]"
              ></div>
              <div
                class="mt-20 md:mt-10 h-32 w-[17%] rounded bg-[#d8d8d8]"
              ></div>
            </div>
          </div>
          <CheckoutDelivery
            v-else
            @openNewAddressModal="addAddressModal = true"
            @showPickupModal="showPickupModal = true"
            @showPhoneModal="showPhoneModal = true"
            :deliveryMethods="checkout?.deliveryMethods"
            :sessionId="checkout?.id"
            :eta="deliveryETA"
            @updateAddress="
              handleUpdateAddress($event.methodId, $event.addressId)
            "
            @refresh="refresh"
            :showPhoneDisclaimer="showPhoneDisclaimer"
          />

          <div
            v-if="loading || isLoading || !checkout"
            class="w-full pt-16 mt-2"
          >
            <div class="flex justify-between items-center">
              <div
                class="h-4 md:h-5 w-2/6 md:w-3/12 rounded-lg bg-[#d8d8d8]"
              ></div>
              <div class="h-3 w-1/6 md:w-1/12 rounded-lg bg-[#d8d8d8]"></div>
            </div>
            <div class="flex items-center gap-x-2">
              <div class="mt-5 h-4 w-4 rounded-full bg-[#d8d8d8]"></div>
              <div
                class="mt-5 h-4 w-2/12 md:w-2/12 rounded-lg bg-[#d8d8d8]"
              ></div>
            </div>
            <div>
              <div
                v-if="isMobileOrTablet"
                class="pl-4 flex justify-center items-center space-x-8"
              >
                <div
                  class="mt-[4.2rem] h-40 w-11/12 rounded-xl bg-[#d8d8d8]"
                ></div>
                <div
                  class="mt-[4.2rem] h-40 w-2/12 rounded-l-xl bg-[#d8d8d8]"
                ></div>
              </div>
            </div>
            <div
              v-if="!isMobileOrTablet"
              class="flex gap-x-12 items-center pl-4"
            >
              <div class="mt-12 h-40 w-10/12 rounded-xl bg-[#d8d8d8]"></div>
              <div class="mt-12 h-40 w-10/12 rounded-xl bg-[#d8d8d8]"></div>
              <div class="mt-12 h-40 w-3/12 rounded-l-lg bg-[#d8d8d8]"></div>
            </div>
          </div>
          <CheckoutPayment
            v-else
            :paymentMethod="
              checkout?.paymentMethods.find((method) => {
                return method.selected
              })
            "
            :paymentMethods="checkout?.paymentMethods"
            :sessionId="checkout?.id"
            :debug="checkout?.debug"
            :total="
              checkout?.totals?.find((total) => {
                return total.type == 'total'
              }).value
            "
            :currency="checkout?.currency?.symbol"
            @updateCreditcard="handleUpdateCreditcard($event)"
            @openNewCreditCardModal="addCreditCardModal = true"
            @refresh="refresh"
          />
          <div class="h-px bg-neutral-light-grey-2"></div>

          <div
            v-if="loading || isLoading || !checkout"
            class="w-full pt-16 md:pt-12"
          >
            <div
              class="h-4 md:h-5 w-11/12 md:w-9/12 rounded-lg bg-[#d8d8d8]"
            ></div>
            <div
              v-if="isMobileOrTablet"
              class="mt-2 h-4 w-2/12 rounded-lg bg-[#d8d8d8]"
            ></div>

            <div class="flex items-center justify-center space-x-2">
              <div
                class="mt-2 md:mt-2 h-14 w-[75%] md:w-[88%] rounded-lg bg-[#d8d8d8]"
              ></div>
              <div
                class="mt-2 md:mt-2 h-14 w-[25%] md:w-[12%] rounded-lg bg-[#d8d8d8]"
              ></div>
            </div>
            <div class="h-px bg-[#d8d8d8] my-6"></div>
          </div>
          <CheckoutCoupons
            v-else
            :sessionId="checkout?.id"
            :coupons="checkout?.coupons"
            @add="addCoupon($event)"
            @remove="removeCoupon($event)"
            :loading="loading"
          />

          <div
            v-if="loading || isLoading || !checkout"
            class="w-full mt-8 mb-6"
          >
            <div class="flex justify-between items-center">
              <div class="h-5 w-2/6 md:w-2/12 rounded-lg bg-[#d8d8d8]"></div>
              <div class="h-5 w-2/6 md:w-2/12 rounded-lg bg-[#d8d8d8]"></div>
            </div>
            <div class="mt-8 md:mt-7 flex justify-between items-center">
              <div class="h-5 w-3/6 md:w-3/12 rounded-lg bg-[#d8d8d8]"></div>
              <div class="h-5 w-1/6 md:w-1/12 rounded-lg bg-[#d8d8d8]"></div>
            </div>
            <div class="mt-8 md:mt-7 flex justify-between items-center">
              <div class="h-5 w-1/6 md:w-1/12 rounded-lg bg-[#d8d8d8]"></div>
              <div class="h-5 w-2/6 md:w-1/12 rounded-lg bg-[#d8d8d8]"></div>
            </div>
            <div class="mt-8 md:mt-7 flex justify-between items-center">
              <div class="h-5 w-1/6 md:w-1/12 rounded-lg bg-[#d8d8d8]"></div>
              <div class="h-5 w-2/6 md:w-2/12 rounded-lg bg-[#d8d8d8]"></div>
            </div>
          </div>
          <CheckoutTotals
            v-else
            :totals="checkout?.totals"
            :currency="checkout?.currency"
          />

          <div
            v-if="loading || isLoading || !checkout"
            class="sticky bottom-0 w-full bg-white pb-4 px-4 pt-2 shadow-inner mdplus:rounded-lg z-50"
          >
            <div
              class="flex justify-between text-sm py-2 mb-4 md:text-base md:py-1"
            >
              <div class="h-5 w-5/12 md:w-3/12 rounded-lg bg-[#d8d8d8]"></div>
              <div class="h-5 w-3/12 md:w-1/12 rounded-lg bg-[#d8d8d8]"></div>
            </div>
            <div class="h-14 w-full rounded bg-[#d8d8d8]"></div>
          </div>
          <CheckoutOrderButton
            v-else
            :total="
              checkout?.totals?.find((total) => {
                return total.type == 'total'
              }).value
            "
            :checkoutId="sessionId"
            :mode="orderButtonMode"
            :status="confirmButtonStatus"
            :currency="checkout?.currency"
            :enableButton="enableButton"
            @confirm="handleConfirm"
            @completed="handleOrderButtonCompleted"
            @error="handleError($event)"
          />
        </div>
      </div>
    </div>

    <ClientOnly>
      <ModalAddAddress
        :visibility="addAddressModal"
        @close="addAddressModal = false"
        @newAddress="refresh"
        @editAddress="refresh"
      />
    </ClientOnly>

    <ClientOnly>
      <ModalAddCreditCard
        :visibility="addCreditCardModal"
        :saving="saving"
        @close="addCreditCardModal = false"
        @submitForm="handleFormSubmit"
        @newCreditCard="refresh"
      />
    </ClientOnly>

    <ClientOnly>
      <ModalCheckoutMyCoupons
        :visibility="showCouponsModal"
        :coupons="checkout?.coupons"
        @close="showCouponsModal = false"
      />
    </ClientOnly>

    <ClientOnly>
      <ModalCheckoutChallenge
        :visibility="showChallengeModal"
        :url="challengeUrl"
        @close="handleCloseChallenge"
        @ok="handleOkChallenge"
        @ko="handleKoChallenge"
      />
    </ClientOnly>

    <ClientOnly>
      <ModalCheckoutPhone
        :visibility="showPhoneModal"
        @update:phone="handleUpdateCustomer($event)"
        @close="showPhoneModal = false"
        :saving="updatingCustomer"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
definePageMeta({
  middleware: 'auth',
  layout: 'checkout',
  colorMode: 'light',
})

const { t, locale } = useI18n({
  useScope: 'local',
})

useHead({
  title: t('FINISH_ORDER'),
})

const runtimeConfig = useRuntimeConfig()
const { addMessage } = useSnackBar()
const terminalId = runtimeConfig.public.PAYCOMET_TERMINAL_ID
const { isMobileOrTablet } = useDevice()
const { calculate } = useETACalculator()
const router = useRouter()
const route = useRoute()
const sessionId = route.params.id
const saving = ref(false)
const selectedShipment = ref()
const selectedPayment = ref()
const isLoading = ref(false)
const showChallengeModal = ref(false)
const challengeUrl = ref(null)
const braintreeButtons = ref(false)
const braintreeLoading = ref(false)
const alias = ref('')
const ccholder = ref('')
const jetToken = ref('')
const methodId = ref(null)
const addressId = ref(null)
const cardId = ref(null)
const paymentMethodId = ref(null)
const couponCode = ref(null)
const couponCodeRemove = ref(null)
const confirmButtonStatus = ref('IDLE') // Estado de reposo
const updatingCustomer = ref(false)

const addAddressModal = ref(false)
const addCreditCardModal = ref(false)
const showCouponsModal = ref(false)
const showPhoneModal = ref(false)

const showPhoneDisclaimer = computed(() => {
  if (!checkout.value) {
    return false
  }

  const deliveryMethodSelected = checkout.value.deliveryMethods.find(
    (method) => {
      return method.selected
    }
  )

  return (
    deliveryMethodSelected.type == 'PICKUP_POINT' &&
    !checkout.value.customer_info.phone
  )
})

const enableButton = computed(() => {
  if (!checkout.value) {
    return false
  }
  let validDelivery = false
  let validPayment = false
  let validPhone = true

  if (checkout.value.deliveryMethods.length > 0) {
    const selectedDeliveryMethod = checkout.value.deliveryMethods.find(
      (method) => {
        return method.selected
      }
    )

    if (selectedDeliveryMethod.type == 'HOME_DELIVERY_24H') {
      const addressSelected = selectedDeliveryMethod.addresses.find(
        (address) => {
          return address.selected
        }
      )

      if (addressSelected) {
        validDelivery = true
      }
    } else {
      // Minimo 1 si la hay es que esta seleccionado el pickup
      validDelivery = selectedDeliveryMethod.addresses.length > 0
    }
  }

  if (checkout.value.paymentMethods.length > 0) {
    const selectedPaymentMethod = checkout.value.paymentMethods.find(
      (method) => {
        return method.selected
      }
    )

    if (selectedPaymentMethod.method == 'creditcard') {
      const selectedCreditcard = selectedPaymentMethod.creditcards.find(
        (creditcard) => {
          return creditcard.selected
        }
      )

      if (selectedCreditcard) {
        validPayment = true
      }
    } else {
      // No es tarjeta, asi que cualquier otro metodo de pago es valido directamente
      validPayment = true
    }
  }

  const deliveryMethodSelected = checkout.value.deliveryMethods.find(
    (method) => {
      return method.selected
    }
  )

  if (
    deliveryMethodSelected.type == 'PICKUP_POINT' &&
    !checkout.value.customer_info.phone
  ) {
    validPhone = false
  }

  return validDelivery && validPayment && validPhone
})

const orderButtonMode = computed(() => {
  if (!checkout.value) {
    return 'normal'
  }
  const selectedPaymentMethod = checkout.value.paymentMethods.find((method) => {
    return method.selected
  })

  if (selectedPaymentMethod.method == 'PayPal') {
    return 'paypal'
  }

  if (selectedPaymentMethod.method == 'Revolut') {
    return 'revolut'
  }

  if (selectedPaymentMethod.method == 'GooglePay') {
    return 'googlepay'
  }

  if (selectedPaymentMethod.method == 'ApplePay') {
    return 'applepay'
  }

  return 'normal'
})

const deliveryETA = computed(() => {
  if (!checkout.value) {
    return null
  }

  const items = checkout.value.shipments.find(Boolean).items

  let eta = null

  for (const item of items) {
    const location = item.location ?? 1
    const crossdocking = item?.crossdocking_suppliers?.length
      ? Number(item.crossdocking_suppliers[0])
      : 0
    // TODO: Cambiar geozone cuando sea necesario
    const tempEta = calculate(crossdocking, location, 2, locale.value)
    if (!eta) {
      eta = tempEta
      continue
    }

    if (new Date(eta.last_date) < new Date(tempEta.last_date)) {
      eta = tempEta
    }
  }

  return eta?.checkout_text
})

const {
  data: checkout,
  pending: loading,
  refresh,
  error,
} = await useLazyFetch(`/api/checkout/${sessionId}`, {
  server: false,
})

watch([checkout], () => {
  if (!checkout.value) {
    return
  }

  if (checkout.value && checkout.value.status === 'COMPLETED') {
    router.push(`/${locale.value}/account/orders`)
  }

  if (checkout.value.shipments.length > 0) {
    selectedShipment.value = checkout.value.shipments[0].name
  }

  selectedPayment.value = checkout.value.paymentMethods.find((method) => {
    return method.selected
  })
})

const {
  execute: executeUpdateCreditcard,
  data: dataUpdateCreditcard,
  error: errorUpdateCreditcard,
} = await useAsyncData(
  'selectCreditcard',
  () =>
    $fetch('/api/checkout/payments/select', {
      method: 'POST',
      body: {
        sessionId: checkout.value.id,
        method: paymentMethodId.value,
        creditCardId: cardId.value,
      },
    }),
  { immediate: false }
)

const {
  execute: executeUpdateAddress,
  data: dataUpdateAddress,
  error: errorUpdateAddress,
} = await useAsyncData(
  'selectPayment',
  () =>
    $fetch('/api/checkout/delivery/select', {
      method: 'POST',
      body: {
        sessionId: checkout.value.id,
        method: methodId.value,
        addressId: addressId.value,
      },
    }),
  { immediate: false }
)

const {
  execute: executeAddCoupon,
  data: dataAddCoupon,
  error: errorAddCoupon,
} = await useAsyncData(
  'addCoupon',
  () =>
    $fetch('/api/checkout/coupons/add', {
      method: 'POST',
      body: {
        sessionId: checkout.value.id,
        couponsCode: [couponCode.value],
      },
    }),
  { immediate: false }
)

const {
  execute: executeRemoveCoupon,
  data: dataRemoveCoupon,
  error: errorRemoveCoupon,
} = await useAsyncData(
  'removeCoupon',
  () =>
    $fetch('/api/checkout/coupons/remove', {
      method: 'POST',
      body: {
        sessionId: checkout.value.id,
        couponCode: couponCodeRemove.value,
      },
    }),
  { immediate: false }
)

const handleConfirm = async () => {
  const implementation = getPaymentMethodImpl()
  if (!implementation) {
    handleError(
      createError({
        statusMessage: t('METHOD_NOT_SUPPORTED'),
      })
    )
    return
  }

  confirmButtonStatus.value = 'CONFIRMING'

  // TODO RAUL Refactorizar onLoad o algo asi para lanzar el mensaje
  const authorizeResult = await implementation.doAuthorize(checkout.value, {
    onApprove: onAuthorizeSuccess,
    onChallenge: onChallenge,
    onError: onAuthorizeError,
    onCompleted: onCompletedSuccess,
  })
}

const handleOrderButtonCompleted = async (paymentData) => {
  await doConfirm(paymentData)
}

const onAuthorizeSuccess = async (result) => {
  await doConfirm(result)
}

const onAuthorizeError = (error) => {
  confirmButtonStatus.value = 'IDLE'
  if (error instanceof Error) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t(error.message),
    })
    return
  }

  addMessage({
    type: 'error',
    result: 'ERROR',
    text: error,
  })
}

const doConfirm = async (data) => {
  try {
    const response = await $fetch(`/api/checkout/confirm`, {
      method: 'POST',
      body: {
        sessionId: checkout.value.id,
        paymentData: data,
      },
    })

    if (response.result === 'KO') {
      addMessage({
        type: 'error',
        result: 'ERROR',
        text: response.message,
      })
      confirmButtonStatus.value = 'IDLE'
      return
    }

    if (response.result === 'COMPLETED') {
      router.push(`/${locale.value}/checkout/${checkout.value.id}/thanku`)
    }
  } catch (error) {
    handleError(error)
    confirmButtonStatus.value = 'IDLE'
  }
}

const onChallenge = async (challengeUrl) => {
  await doChallenge(challengeUrl)
}

const doChallenge = async (url) => {
  // TODO: dependiendo de la implementacion o haces router o haces iframe
  const selectedPaymentMethod = checkout.value.paymentMethods.find((method) => {
    return method.selected
  })

  if (selectedPaymentMethod.method === 'Bizum') {
    // crear ventana emergente con el iframe y añadir listener de mensajes
    // para cerrar la ventana emergente
    const popup = window.open(url, '_blank', 'width=600,height=600')

    // TODO: Hacer faldon con mensaje y donde es para habilitar, etc
    if (!popup || popup.closed || typeof popup.closed == 'undefined') {
      addMessage({
        type: 'error',
        result: 'ERROR',
        text: t('ENABLE_POPUPS'),
      })
      return
    }

    // Configurar el listener de mensajes
    const handleMessage = async (event) => {
      const config = useRuntimeConfig()
      // Asegúrate de que el mensaje proviene del origen correcto
      if (event.origin !== config.public.APP_URL) return

      console.log(event)

      // Cerrar la ventana emergente
      popup.close()

      // Procesar el resultado
      if (event.data && event.data.result) {
        if (event.data.result === 'OK') {
          await handleOkChallenge()
        } else {
          handleKoChallenge(event.data.message)
        }

        // Eliminar el event listener
        window.removeEventListener('message', handleMessage)
      }
    }

    // Añadir el event listener
    window.addEventListener('message', handleMessage)
  } else {
    challengeUrl.value = url
    showChallengeModal.value = true
  }
}

const handleKoChallenge = (message) => {
  confirmButtonStatus.value = 'IDLE'
  showChallengeModal.value = false
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: message ?? t('SOMETHING_GET_WRONG'),
  })
}

const handleOkChallenge = async () => {
  console.log('handleOkChallenge')
  const implementation = getPaymentMethodImpl()
  const completedResult = await implementation.doResult(checkout.value, {
    onCompleted: onCompletedSuccess,
    onError: onCompletedError,
  })
}

const onCompletedSuccess = () => {
  confirmButtonStatus.value = 'IDLE'
  showChallengeModal.value = false
  router.push(`/${locale.value}/checkout/${checkout.value.id}/thanku`)
}

const onCompletedError = (message) => {
  confirmButtonStatus.value = 'IDLE'
  showChallengeModal.value = false
  if (!message) {
    message = t('SOMETHING_GET_WRONG')
  }
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: message,
  })
}

const handleUpdateAddress = async (newMethodId, newAddressId) => {
  const selectedDeliveryMethod = checkout.value.deliveryMethods.find(
    (method) => method.id == newMethodId
  )

  const addressSelected = selectedDeliveryMethod.addresses.find(
    (address) => address.selected
  )

  if (addressSelected && addressSelected.id == newAddressId) {
    return
  }

  methodId.value = newMethodId
  addressId.value = newAddressId

  isLoading.value = true

  await executeUpdateAddress()

  if (errorUpdateAddress.value) {
    handleError(errorUpdateAddress.value)
  }

  if (dataUpdateAddress.value?.result == 'ok') {
    checkout.value = dataUpdateAddress.value.session
  }

  isLoading.value = false
}

const handleUpdateCreditcard = async (newCardId) => {
  const selectedCreditcard = checkout.value.paymentMethods
    .find((method) => method.method === 'creditcard')
    ?.creditcards?.find((creditcard) => creditcard.selected)

  if (selectedCreditcard && selectedCreditcard.id === newCardId) {
    return
  }

  cardId.value = newCardId
  paymentMethodId.value = checkout.value.paymentMethods.find(
    (method) => method.method === 'creditcard'
  )?.id

  isLoading.value = true

  await executeUpdateCreditcard()

  if (errorUpdateCreditcard.value) {
    handleError(errorUpdateCreditcard.value)
  }

  if (dataUpdateCreditcard.value?.result === 'OK') {
    checkout.value = dataUpdateCreditcard.value.session
  }

  isLoading.value = false
}

const handleCloseChallenge = () => {
  showChallengeModal.value = false
  challengeUrl.value = null
}

const addCoupon = async (coupon) => {
  loading.value = true

  couponCode.value = coupon

  await executeAddCoupon()

  if (errorAddCoupon.value) {
    addMessage({
      type: 'error',
      result: t('ERROR'),
      text: t('ERROR_ADDING_COUPON'),
    })
    loading.value = false
    return
  }

  checkout.value = dataAddCoupon.value.session
  const message = checkout.value.message
  addMessage({
    type: message.type,
    result: message.title,
    text: message.text,
  })

  loading.value = false
}

const removeCoupon = async (selectedCoupon) => {
  loading.value = true

  couponCodeRemove.value = selectedCoupon.code

  await executeRemoveCoupon()

  if (errorRemoveCoupon.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR_ADDING_COUPON'),
    })
    loading.value = false
    return
  }

  checkout.value = dataRemoveCoupon.value.session
  const message = checkout.value.message
  addMessage({
    type: message.type,
    result: message.title,
    text: message.text,
  })

  loading.value = false
}

const { execute, error: tokenizeError } = useAsyncData(
  'tokenize',
  () =>
    $fetch('/api/checkout/creditcards/newandselect', {
      method: 'POST',
      body: {
        sessionId: sessionId,
        terminal: terminalId,
        alias: alias.value,
        ccholder: ccholder.value,
        jetToken: jetToken.value,
      },
    }),
  { immediate: false }
)

const handleFormSubmit = async ({ ccAlias, holder, ccToken }) => {
  alias.value = ccAlias
  ccholder.value = holder
  jetToken.value = ccToken

  saving.value = true

  await execute()

  if (tokenizeError.value) {
    handleError(tokenizeError.value)
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('CREDITCARD_ADDED_SUCCEED'),
    })
    addCreditCardModal.value = false
    refresh()
  }

  saving.value = false
}

const handleUpdateCustomer = async (phone) => {
  updatingCustomer.value = true
  try {
    const response = await $fetch(`/api/checkout/customer/update`, {
      method: 'POST',
      body: {
        sessionId: checkout.value.id,
        phone: phone,
      },
    })
    checkout.value = response
  } catch (error) {
    handleError(error)
    updatingCustomer.value = false
    return
  }
  updatingCustomer.value = false
  showPhoneModal.value = false
}

// TODO TIPAR ERROR
const handleError = (error) => {
  // TODO REFACTOR REVISANDO TODOS LOS METODOS DE PAGO, ESTO ES PARA RAUL
  if (error?.type) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: error.message,
    })
    return
  }

  let message = error?.data?.message || error?.message
  switch (message) {
    case 'CUSTOMER_ABORT':
      message = 'CUSTOMER_ABORT'
      break

    case 'INVALID_DATA':
      message = 'INVALID_DATA'
      break

    case 'TRANSACTION_DECLINED':
      message = 'TRANSACTION_DECLINED'
      break

    default:
      message = 'UNKNOWN_ERROR'
      break
  }

  addMessage({
    type: 'error',
    result: 'ERROR',
    text: t(message),
  })
}

const getPaymentMethodImpl = () => {
  const implementation = selectedPayment.value.implementation
  switch (implementation) {
    case 'CoDImpl':
      const { implementation: cod } = useCoD() // IMPLEMENTADO
      return cod
    case 'WireTransferImpl':
      const { implementation: wire } = useWireTransfer() // IMPLEMENTADO
      return wire
    case 'PaycometImpl':
      const { implementation: payment } = usePaycomet()
      return payment
    case 'KlarnaImpl':
      const { implementation: klarna } = useKlarna()
      return klarna
    case 'PayPalImpl':
      const { implementation: paypal } = usePayPal()
      return paypal
    case 'BizumImpl':
    case 'RedsysImpl':
      const { implementation: redsys } = useRedsys()
      return redsys
    case 'RevolutImpl':
      const { implementation: revolut } = useRevolut()
      return revolut
    case 'MultisafepayImpl':
      const { implementation: multisafepay } = useMultisafepay()
      return multisafepay

    // case 'MBWayImpl':
    //   const { implementation: mbWay } = useMBWay()
    //   return mbWay
    // case 'MultibancoImpl':
    //   const { implementation: multibanco } = useMultibanco()
    //   return multibanco

    // case 'GooglePayImpl':
    //   const { implementation: googlePay } = useGooglePay()
    //   return googlePay
  }
  return null
}
</script>
