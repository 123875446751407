<i18n>
  {
    "es": {
      "SOMETHING_GET_WRONG": "Algo ha ido mal, por favor, inténtalo de nuevo.",
      "CONNECTING": "Conectando con la pasarela de pago. Un momento, por favor...",
    },
    "en": {
      "SOMETHING_GET_WRONG": "Something went wrong, please try again.",
      "CONNECTING": "Connecting to the payment gateway. Please wait a moment...",
    },
    "fr": {
      "SOMETHING_GET_WRONG": "Quelque chose s'est mal passé, veuillez réessayer.",
      "CONNECTING": "Connexion à la passerelle de paiement. Veuillez patienter un moment...",
    },
    "it": {
      "SOMETHING_GET_WRONG": "Qualcosa è andato storto, riprova.",
      "CONNECTING": "Connessione al gateway di pagamento. Attendere un momento...",
    },
    "de": {
      "SOMETHING_GET_WRONG": "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
      "CONNECTING": "Verbindung zum Zahlungsgateway. Bitte warten Sie einen Moment...",
    },
    "pt": {
      "SOMETHING_GET_WRONG": "Algo correu mal, por favor, tente novamente.",
      "CONNECTING": "Conectando ao gateway de pagamento. Por favor, aguarde um momento...",
    },
  }
</i18n>

<template>
  <ModalCourtain
    :visibility="visibility"
    @close="emit('close')"
    :closeOnClickOutside="false"
  >
    <template v-slot:title> </template>
    <template v-slot:content>
      <div
        v-if="isLoading && challengeUrl"
        class="relative isolate overflow-hidden before:border-t before:border-white/40 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/40 before:to-transparent"
      >
        <SpinnerFlower :colored="true" :visibility="true" />
        <p
          class="text-sanremo-black mt-4 tracking-wide text-center text-sm md:text-base px-4 leading-5"
        >
          {{ t('CONNECTING') }}
        </p>
      </div>

      <div v-else class="w-full min-h-[550px]">
        <iframe ref="iframe" :src="url" class="w-full min-h-[550px]"></iframe>
      </div>
    </template>
  </ModalCourtain>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  url: String,
})

const emit = defineEmits(['close', 'ko', 'ok'])
const isLoading = ref(false)
const iframe = ref()
const { t } = useI18n()

onMounted(() => {
  iframe.value.addEventListener('load', () => {
    isLoading.value = false
  })

  window.addEventListener('message', (event) => {
    if (!props.visibility) return
    const data = event.data
    const result = data.result

    switch (result) {
      case 'OK':
        emit('ok')
        break

      case 'KO':
        emit('ko')
        break
    }
  })
})

// TODO Eliminar listener en el ounmounted
</script>
