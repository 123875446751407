<i18n>
  {
    "es": {
      "HAVE_COUPON": "Canjea aquí un código de descuento",
      "TEXT_COUPON": "Introdúcelo ahora mismo :)",
      "COUPON_PLACEHOLDER": "Introduce el código",
      "APPLY": "APLICAR",
      "COUPON_INFO": "Hay cupones en tu cesta que no estan activos, revisa las condiciones de la promocion.",
      "ERROR_ADDING_COUPON": "Error al añadir el cupón",
      "COUPON_ADDED": "Cupón añadido correctamente",
    },
    "en": {
      "HAVE_COUPON": "Redeem a discount code or gift card here",
      "TEXT_COUPON": "Enter it right now :)",
      "COUPON_PLACEHOLDER": "Enter the code",
      "APPLY": "APPLY",
      "COUPON_INFO": "There are coupons in your cart that are not active, check the promotion conditions.",
      "ERROR_ADDING_COUPON": "Error adding coupon",
      "COUPON_ADDED": "Coupon added successfully",
    },
    "fr": {
      "HAVE_COUPON": "Échangez un code de réduction ou une carte cadeau ici",
      "TEXT_COUPON": "Entrez-le maintenant :)",
      "COUPON_PLACEHOLDER": "Entrez le code",
      "APPLY": "APPLIQUER",
      "COUPON_INFO": "Il y a des coupons dans votre panier qui ne sont pas actifs, vérifiez les conditions de la promotion.",
      "ERROR_ADDING_COUPON": "Erreur lors de l'ajout du coupon",
      "COUPON_ADDED": "Coupon ajouté avec succès",
    },
    "de": {
      "HAVE_COUPON": "Lösen Sie hier einen Rabattcode oder ein Geschenkkarte ein",
      "TEXT_COUPON": "Geben Sie ihn jetzt ein :)",
      "COUPON_PLACEHOLDER": "Geben Sie den Code ein",
      "APPLY": "ANWENDEN",
      "COUPON_INFO": "Es gibt Gutscheine in Ihrem Warenkorb, die nicht aktiv sind. Überprüfen Sie die Aktionsbedingungen.",
      "ERROR_ADDING_COUPON": "Fehler beim Hinzufügen des Gutscheins",
      "COUPON_ADDED": "Gutschein erfolgreich hinzugefügt",
    },
    "it": {
      "HAVE_COUPON": "Riscatta un codice sconto o una carta regalo qui",
      "TEXT_COUPON": "Inseriscilo subito :)",
      "COUPON_PLACEHOLDER": "Inserisci il codice",
      "APPLY": "APPLICA",
      "COUPON_INFO": "Ci sono coupon nel tuo carrello che non sono attivi, controlla le condizioni della promozione.",
      "ERROR_ADDING_COUPON": "Errore durante l'aggiunta del coupon",
      "COUPON_ADDED": "Coupon aggiunto con successo",
    },
    "pt": {
      "HAVE_COUPON": "Resgate um código de desconto ou cartão presente aqui",
      "TEXT_COUPON": "Insira agora mesmo :)",
      "COUPON_PLACEHOLDER": "Insira o código",
      "APPLY": "APLICAR",
      "COUPON_INFO": "Existem cupons no seu carrinho que não estão ativos, verifique as condições da promoção.",
      "ERROR_ADDING_COUPON": "Erro ao adicionar cupom",
      "COUPON_ADDED": "Cupom adicionado com sucesso",
    },
  }
</i18n>

<template>
  <div>
    <div class="flex justify-between items-center pt-8">
      <div class="flex justify-start items-center gap-x-2">
        <h2 class="flex justify-start text-sm md:text-base leading-5">
          {{ t('HAVE_COUPON') }}
        </h2>
      </div>
    </div>

    <div id="checkout-coupons" clas ref="couponDiv">
      <div class="flex items-start bg-white">
        <div class="flex-1">
          <div
            class="flex items-start justify-between text-sm font-bold text-others-black mb-1"
          >
            <div class="text-sm font-semibold tracking-wider leading-5">
              <div
                v-if="loading"
                class="flex flex-col justify-start items-start gap-y-1 w-full"
              >
                <div class="w-72 h-4 rounded-lg bg-[#d8d8d8]"></div>
                <div class="w-32 h-4 rounded-lg bg-[#d8d8d8]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div
          v-if="loading"
          class="w-full h-10 my-6 rounded-lg bg-[#d8d8d8]"
        ></div>
        <div v-else>
          <div class="overflow-hidden">
            <div
              class="my-1 flex items-center justify-between space-x-2 rounded bg-white"
            >
              <input
                @focus="adjustScroll"
                @input="updateInput"
                type="text"
                id="coupon"
                name="coupon"
                :value="coupon"
                :placeholder="t('COUPON_PLACEHOLDER')"
                class="h-14 flex-1 appearance-none rounded-md pl-4 border border-others-grey3 text-sm focus:outline-none text-sanremo-black font-medium tracking-wide"
              />
              <button
                @click="addCoupon"
                :disabled="!applicable"
                :class="!applicable ? 'cursor-not-allowed' : ''"
                class="h-14 rounded-md px-4 text-sm text-white font-bold bg-sanremo-black"
              >
                {{ t('APPLY') }}
              </button>
            </div>

            <div
              class="scrollbar-hidden overflow-x-auto mt-4"
              v-if="coupons.length"
            >
              <div class="inline-flex space-x-4 text-sm">
                <CheckoutCoupon
                  v-for="coupon in coupons"
                  :key="coupon.id"
                  :sessionId="sessionId"
                  :coupon="coupon"
                  @remove="removeCoupon(coupon)"
                />
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div v-if="loading" class="my-1.5 h-px bg-[#d8d8d8] mb-8"></div>
      <div v-else class="h-px bg-others-black my-8"></div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
  coupons: {
    type: Array,
    default: () => [],
  },
  loading: Boolean,
})

const { addMessage } = useSnackBar()

const emit = defineEmits(['handleLoading'])

const { t } = useI18n({
  useScope: 'local',
})

const coupon = ref('')

watch(coupon, (newCoupon, prevCoupon) => {
  coupon.value = newCoupon.toUpperCase()
})

const updateInput = (event) => {
  coupon.value = event.target.value
}

const applicable = computed(() => {
  return coupon.value != ''
})

const couponDiv = ref(null)

const adjustScroll = () => {
  var element = couponDiv.value
  element.scrollIntoView()
}

const addCoupon = async () => {
  emit('add', coupon.value)
  coupon.value = ''
}

const removeCoupon = async (selectedCoupon) => {
  emit('remove', selectedCoupon)
}
</script>
