<i18n>
  {
    "es": {
        "PAYMENT_METHOD": "Método de pago",
        "CHANGE": "Cambiar",
        "ADD_CARD": "Añadir tarjeta",
        "MY_CARDS": "Mis tarjetas",
        "ERROR_SETTING": "Error al seleccionar el método de pago",
        "ERROR": "ERROR",
        "PAYMENT_UPDATED" : "Método de pago seleccionado",
        "VALID": "Válida",
        "TERMINATED": "Terminada en **",
        "OTHER_CARDS": "Otras tarjetas",
        "ARROW_ICON": "Icono de flecha a la derecha",
        "CHECK_ICON": "Icono de check verde",
        "OTHER_PAYMENT_METHODS": "Otros métodos de pago",
        "HIDE_OTHER_METHODS": "Ocultar otros métodos",
        "INFO_ICON": "Icono de información",
        "CREDITCARD_DELETED": "Tarjeta eliminada",
        "MAIN_CARD_UPDATED": "Tarjeta principal actualizada",
    },
    "en": {
      "PAYMENT_METHOD": "Payment method",
      "CHANGE": "Change",
      "ADD_CARD": "Add creditcard",
      "MY_CARDS": "My cards",
      "ERROR_SETTING": "Error selecting payment method",
      "ERROR": "ERROR",
      "PAYMENT_UPDATED": "Payment method selected",
      "VALID": "Valid",
      "TERMINATED": "Terminated in **",
      "OTHER_CARDS": "Other creditcards",
      "ARROW_ICON": "Right arrow icon",
      "CHECK_ICON": "Green check icon",
      "OTHER_PAYMENT_METHODS": "Other payment methods",
      "HIDE_OTHER_METHODS": "Hide other methods",
      "INFO_ICON": "Information icon",
      "CREDITCARD_DELETED": "Creditcard deleted",
      "MAIN_CARD_UPDATED": "Main card updated",
    },
    "it": {
      "PAYMENT_METHOD": "Metodo di pagamento",
      "CHANGE": "Cambia",
      "ADD_CARD": "Aggiungi carta",
      "MY_CARDS": "Le mie carte",
      "ERROR_SETTING": "Errore nella selezione del metodo di pagamento",
      "ERROR": "ERRORE",
      "PAYMENT_UPDATED": "Metodo di pagamento selezionato",
      "VALID": "Valida",
      "TERMINATED": "Terminata in **",
      "OTHER_CARDS": "Altre carte",
      "ARROW_ICON": "Icona freccia destra",
      "CHECK_ICON": "Icona check verde",
      "INFO_ICON": "Icono de información",
      "CREDITCARD_DELETED": "Carta di credito eliminata",
      "MAIN_CARD_UPDATED": "Carta principale aggiornata",
    },
    "fr": {
      "PAYMENT_METHOD": "Méthode de paiement",
      "CHANGE": "Changer",
      "ADD_CARD": "Ajouter une carte",
      "MY_CARDS": "Mes cartes",
      "ERROR_SETTING": "Erreur lors de la sélection de la méthode de paiement",
      "ERROR": "ERREUR",
      "PAYMENT_UPDATED": "Méthode de paiement sélectionnée",
      "VALID": "Valide",
      "TERMINATED": "Terminée en **",
      "OTHER_CARDS": "Autres cartes",
      "ARROW_ICON": "Icône flèche droite",
      "CHECK_ICON": "Icône check vert",
      "INFO_ICON": "Icono de información",
      "CREDITCARD_DELETED": "Carte de crédit supprimée",
      "MAIN_CARD_UPDATED": "Carte principale mise à jour",
    },
    "de": {
      "PAYMENT_METHOD": "Zahlungsmethode",
      "CHANGE": "Ändern",
      "ADD_CARD": "Karte hinzufügen",
      "MY_CARDS": "Meine Karten",
      "ERROR_SETTING": "Fehler bei der Auswahl der Zahlungsmethode",
      "ERROR": "FEHLER",
      "PAYMENT_UPDATED": "Zahlungsmethode ausgewählt",
      "VALID": "Gültig",
      "TERMINATED": "Beendet in **",
      "OTHER_CARDS": "Andere Karten",
      "ARROW_ICON": "Rechter Pfeil-Icon",
      "CHECK_ICON": "Grünes Häkchen-Icon",
      "INFO_ICON": "Icono de información",
      "CREDITCARD_DELETED": "Kreditkarte gelöscht",
      "MAIN_CARD_UPDATED": "Hauptkarte aktualisiert",
    },
    "pt": {
      "PAYMENT_METHOD": "Método de pagamento",
      "CHANGE": "Alterar",
      "ADD_CARD": "Adicionar cartão",
      "MY_CARDS": "Meus cartões",
      "ERROR_SETTING": "Erro ao selecionar o método de pagamento",
      "ERROR": "ERRO",
      "PAYMENT_UPDATED": "Método de pagamento selecionado",
      "VALID": "Válido",
      "TERMINATED": "Terminado em **",
      "OTHER_CARDS": "Outros cartões",
      "ARROW_ICON": "Ícone de seta para a direita",
      "CHECK_ICON": "Ícone de check verde",
      "INFO_ICON": "Icono de información",
      "CREDITCARD_DELETED": "Cartão de crédito excluído",
      "MAIN_CARD_UPDATED": "Cartão principal atualizado",
    }
  }
</i18n>

<template>
  <div class="w-full mb-5">
    <div class="flex justify-between items-center">
      <h2 class="mt-2 flex justify-start text-base sm:text-base md:text-xl">
        {{ t('PAYMENT_METHOD') }}
      </h2>

      <button
        v-if="selectedPaymentMethod?.method === 'creditcard' && hasSavedCards"
        @click="toggleCollapse"
        class="mt-3 text-right text-xxs mb-1 tracking-wide md:tracking-wider underline underline-offset-2"
      >
        {{ collapse ? t('HIDE_OTHER_METHODS') : t('OTHER_PAYMENT_METHODS') }}
      </button>
    </div>

    <div class="mt-6 w-full flex flex-col gap-4">
      <div v-for="method in filteredPaymentMethods" :key="method.id">
        <button
          @click="selectPaymentMethod(method.id)"
          class="flex items-center border rounded-lg p-4 w-full"
          :class="[
            method.selected
              ? 'border-sanremo-primaryblue border-2 bg-sanremo-primaryblue/5'
              : 'border',
            method.max_amount < total ? 'opacity-50 cursor-not-allowed' : '',
          ]"
          :disabled="method.max_amount < total || method.selected || updating"
        >
          <img
            :src="method.img.src"
            class="w-12 h-8 mr-4"
            :alt="t('PAYMENT_METHOD')"
          />
          <div class="flex flex-col items-start space-y-1">
            <div class="flex items-center w-full">
              <p class="text-xs tracking-wide">{{ method.title }}</p>
              <p v-if="method.cost > 0" class="ml-1 text-xxs text-sanremo-gray">
                (+{{ method.cost }}{{ currency }})
              </p>
              <button
                type="button"
                @click.stop="openPaymentInfoModal(method)"
                aria-label="paymentmodal"
                class="ml-1"
              >
                <img
                  src="@/assets/svg/icons/icons-info.svg"
                  class="w-3 h-3"
                  :alt="t('INFO_ICON')"
                />
              </button>
            </div>
            <p
              v-if="method?.subtitle"
              class="text-xxs text-sanremo-gray tracking-wider"
            >
              {{ method?.subtitle }} {{ method?.max_amount }} {{ currency }}
            </p>
            <p
              v-else-if="method?.disclaimer"
              class="text-xxs text-sanremo-gray tracking-wider"
            >
              {{ method?.disclaimer }}
            </p>
          </div>
        </button>

        <div
          v-if="method.method === 'creditcard' && method.selected"
          class="mt-4"
        >
          <div v-if="selectedCreditcard" class="mt-4">
            <button
              :key="selectedCreditcard.id"
              @click="emit('updateCreditcard', selectedCreditcard?.id)"
              class="flex justify-between items-center text-sanremo-black border mt-4 rounded-lg w-full px-4 py-1.5"
              :class="selectedCreditcard?.selected ? 'border-gray-300' : ''"
              :disabled="selectedCreditcard?.selected"
            >
              <div class="w-full flex justify-start items-center gap-x-5">
                <img
                  v-if="selectedCreditcard.brand === 'VISA'"
                  src="@/assets/svg/icons/icons-visa-border.svg"
                  alt="icono de visa"
                />
                <img
                  v-if="selectedCreditcard.brand === 'MASTERCARD'"
                  src="@/assets/svg/icons/icons-mastercard-border.svg"
                  alt="icono de mastercard"
                />
                <div class="w-full flex items-center space-x-5 lg:space-x-6">
                  <p
                    class="text-xxs md:text-xs tracking-wider text-gray-500 capitalize"
                  >
                    {{ selectedCreditcard.alias }}
                  </p>

                  <p
                    class="text-xxs md:text-xs tracking-wider text-sanremo-black font-semibold whitespace-nowrap"
                  >
                    {{
                      isDesktop
                        ? selectedCreditcard.pan
                        : selectedCreditcard.pan.slice(-9)
                    }}
                  </p>

                  <p
                    class="text-xxs md:text-xs tracking-wider text-gray-500 whitespace-nowrap"
                  >
                    {{
                      dateFormattingToCreditCard(selectedCreditcard.expire_at)
                    }}
                  </p>
                </div>
              </div>
              <img
                v-if="selectedCreditcard?.selected"
                src="@/assets/svg/icons/icons-check-green.svg"
                :alt="t('CHECK_ICON')"
                class="ml-2 h-6 w-6"
              />
              <img
                v-else
                src="@/assets/svg/icons/black/icons-right-arrow.svg"
                :alt="t('ARROW_ICON')"
                class="ml-2"
              />
            </button>
          </div>

          <div class="mt-4 mb-0 flex justify-between">
            <button
              @click="emit('openNewCreditCardModal')"
              class="text-left text-xxs mb-1 tracking-wide md:tracking-wider underline underline-offset-2"
            >
              {{ t('ADD_CARD') }}
            </button>
            <button
              v-if="selectedPaymentMethod.creditcards.length > 1"
              @click="selectOtherCreditCard = true"
              class="text-right text-xxs mb-1 tracking-wide md:tracking-wider underline underline-offset-2"
            >
              {{ t('OTHER_CARDS') }}
            </button>
          </div>
        </div>
      </div>

      <CheckoutCollapsePaymentMethods
        v-if="
          (selectedPaymentMethod?.method === 'creditcard' && hasSavedCards) ||
          selectedPaymentMethod?.method !== 'creditcard'
        "
        :visibility="selectOtherPaymentMethod"
        :paymentMethods="nonSelectedPaymentMethods"
        :total="total"
        :collapse="collapse"
        :currency="currency"
        @selectPaymentMethod="selectPaymentMethod($event)"
        @openPaymentInfoModal="openPaymentInfoModal($event)"
      />
    </div>

    <ClientOnly>
      <ModalCheckoutPaymentInfo
        :visibility="paymentInfoModal"
        :methodInfo="selectedMethodInfo"
        @close="paymentInfoModal = false"
      />
    </ClientOnly>

    <ClientOnly>
      <LazyModalCheckoutOthersCreditcardsList
        :visibility="selectOtherCreditCard"
        :creditcards="nonSelectedCreditcards"
        @close="selectOtherCreditCard = false"
        @selectCreditCard="emit('updateCreditcard', $event)"
      />
    </ClientOnly>

    <ClientOnly>
      <LazyModalCreditcardConfirmDeleteCheckout
        :visibility="confirmDeleteModal"
        :creditcard="creditcard"
        :deleting="deleting"
        @delete="remove(creditcard.id)"
        @close="confirmDeleteModal = false"
      />
    </ClientOnly>

    <ClientOnly>
      <LazyModalCreditcardConfirmDefaultCheckout
        :visibility="confirmDefaultModal"
        :creditcard="creditcard"
        :updating="updating"
        @update="setAsPrincipal(creditcard.id)"
        @close="confirmDefaultModal = false"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
  debug: {
    type: Boolean,
    default: false,
  },
  total: {
    type: Number,
    required: true,
  },
  paymentMethod: {
    type: Object,
  },
  paymentMethods: {
    type: Array,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
})

const creditcard = ref(null)
const confirmDeleteModal = ref(false)
const confirmDefaultModal = ref(false)
const paymentInfoModal = ref(false)
const selectedMethodInfo = ref(null)
const selectOtherCreditCard = ref(false)
const selectOtherPaymentMethod = ref(false)
const deleting = ref(false)
const updating = ref(false)
const { isDesktop, isIos, isAndroid } = useDevice()
const { addMessage } = useSnackBar()
const collapse = ref(false)

const { t } = useI18n({
  useScope: 'local',
})

const { locale } = useI18n()

const emit = defineEmits([
  'updateCreditcard',
  'refresh',
  'openNewCreditCardModal',
])

const availablePaymentMethods = computed(() => {
  return props.paymentMethods.filter((method) => {
    if (import.meta.dev) return true

    if (!import.meta.dev && method.debug && props.debug) return true

    if (method.method == 'GooglePay') {
      return isAndroid && !method.debug
    }

    if (method.method == 'ApplePay') {
      return isIos && !method.debug
    }

    return true
  })
})

const filteredPaymentMethods = computed(() => {
  const selectedMethod = props.paymentMethods.find((method) => method.selected)

  if (selectedMethod?.method === 'creditcard') {
    if (!hasSavedCards.value) {
      return availablePaymentMethods.value
    }

    return [selectedMethod]
  }

  return [
    selectedMethod,
    ...availablePaymentMethods.value.filter(
      (method) => method.id !== selectedMethod.id
    ),
  ]
})

const nonSelectedPaymentMethods = computed(() => {
  return availablePaymentMethods.value.filter((method) => !method.selected)
})

const selectedPaymentMethod = computed(() => {
  return props.paymentMethods.find((method) => method.selected)
})

const selectedCreditcard = computed(() => {
  const selected = selectedPaymentMethod?.value.creditcards.find(
    (card) => card.selected
  )

  return (
    selected || selectedPaymentMethod?.value.creditcards.find(Boolean) || null
  )
})

const nonSelectedCreditcards = computed(() => {
  return (
    selectedPaymentMethod?.value.creditcards.filter((card) => !card.selected) ||
    []
  )
})

const hasSavedCards = computed(() => {
  return (
    props.paymentMethod?.creditcards &&
    props.paymentMethod.creditcards.length > 0
  )
})

const openPaymentInfoModal = (method) => {
  selectedMethodInfo.value = method
  paymentInfoModal.value = true
}

const activeTab = ref(hasSavedCards.value ? 'myCards' : 'addCard')

const selectPaymentMethod = async (methodId) => {
  updating.value = true

  const { data, error } = await useAsyncData('selectPaymentMethod', () =>
    $fetch('/api/checkout/payments/select', {
      method: 'POST',
      body: {
        sessionId: props.sessionId,
        method: methodId,
      },
    })
  )

  if (error.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR_SETTING'),
    })
  } else {
    props.paymentMethods.forEach((method) => {
      method.selected = method.id === methodId
    })
  }
  updating.value = false
  emit('refresh')
}

const confirmRemove = (id) => {
  const creditcardInCreditcards = props.paymentMethod?.creditcards?.find(
    (creditcard) => creditcard.id == id
  )

  creditcard.value = creditcardInCreditcards
  confirmDeleteModal.value = true
}

const remove = async (id) => {
  deleting.value = true
  const { error } = await useAsyncData(`deleteCard${id}`, () =>
    $fetch(`/api/account/creditcards/${id}/delete`)
  )
  if (error.value) {
    handleError(error.value)
    deleting.value = false
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('CREDITCARD_DELETED'),
    })
    confirmDeleteModal.value = false
    deleting.value = false
    emit('refresh')
  }
}

const handleError = (error) => {
  console.debug(error)
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: t('ERROR'),
  })
}

const confirmDefault = (id) => {
  const creditcardInCreditcards = props.paymentMethod?.creditcards?.find(
    (creditcard) => creditcard.id == id
  )
  creditcard.value = creditcardInCreditcards
  confirmDefaultModal.value = true
}

const setAsPrincipal = async (id) => {
  updating.value = true
  const { error } = await useAsyncData(
    `updatedefaultcreditCard`,
    () =>
      $fetch(`/api/account/creditcards/${id}/default`, {
        method: 'GET',
      }),
    {}
  )
  if (error.value) {
    handleError(error.value)
    updating.value = false
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('MAIN_CARD_UPDATED'),
    })
    confirmDefaultModal.value = false
    updating.value = false
    emit('refresh')
  }
}

const formSubmit = async (data) => {
  const { alias: ccAlias, ccholder: holder, token: ccToken } = data

  emit('submitForm', { ccAlias, holder, ccToken })
}

const toggleCollapse = () => {
  collapse.value = !collapse.value
}
</script>
