<i18n>
{
  "es": {
    "TOTAL": "Total",
    "IVA_INCLUDED": "IVA incluido",
    "MAKE_PURCHASE": "Realizar compra",
    "MAKING_PURCHASE": "Autorizando...",
    "METHOD_NOT_SUPPORTED": "Método de Pago no Soportado",
    "CUSTOMER_ABORT": "Proceso Cancelado",
    "INVALID_DATA": "Datos no validos",
    "INVALID_ADDRESS": "Dirección no válida",
    "INVALID_CITY": "Ciudad no válida",
    "INVALID_NAME": "Nombre no válido",
    "INVALID_POSTCODE": "Código Postal no válida",
    "INVALID_LASTNAME": "Apellido no válido",
    "INVALID_EMAIL": "Correo Electrónico no válido",
    "TRANSACTION_DECLINED": "Transacción Rechazada",
    "UNKNOWN_ERROR": "Upss.. Ha ocurrido un error inesperado. Vuelve a intentarlo.",
    "SOMETHING_GET_WRONG": "Upss.. Algo ha ido mal. Vuelve a intentarlo.",
    "PAYMENT_CANCELLED": "Pago cancelado.",
  },
  "en": {
    "TOTAL": "Total",
    "IVA_INCLUDED": "VAT included",
    "MAKE_PURCHASE": "Make purchase",
    "MAKING_PURCHASE": "Authorizing...",
    "METHOD_NOT_SUPPORTED": "Payment Method not Supported",
    "CUSTOMER_ABORT": "Process Aborted",
    "INVALID_DATA": "Invalid Data",
    "INVALID_ADDRESS": "Invalid Address",
    "INVALID_CITY": "Invalid City",
    "INVALID_NAME": "Invalid Name",
    "INVALID_POSTCODE": "Invalid Postcode",
    "INVALID_LASTNAME": "Invalid Lastname",
    "INVALID_EMAIL": "Invalid Email",
    "TRANSACTION_DECLINED": "Transaction Declined",
    "UNKNOWN_ERROR": "Upss.. An unexpected error has occurred. Try again.",
    "SOMETHING_GET_WRONG": "Upss.. Something went wrong. Try again.",
    "PAYMENT_CANCELLED": "Payment cancelled.",
  },
  "it": {
    "TOTAL": "Totale",
    "IVA_INCLUDED": "IVA inclusa",
    "MAKE_PURCHASE": "Fai l'acquisto",
    "MAKING_PURCHASE": "Autorizzazione...",
    "METHOD_NOT_SUPPORTED": "Metodo di pagamento non supportato",
    "CUSTOMER_ABORT": "Processo Annullato",
    "INVALID_DATA": "Dati non validi",
    "INVALID_ADDRESS": "Indirizzo non valido",
    "INVALID_CITY": "Città non valida",
    "INVALID_NAME": "Nome non valido",
    "INVALID_POSTCODE": "Codice Postale non valido",
    "INVALID_LASTNAME": "Cognome non valido",
    "INVALID_EMAIL": "Email non valida",
    "TRANSACTION_DECLINED": "Transazione Rifiutata",
    "UNKNOWN_ERROR": "Upss.. Si è verificato un errore imprevisto. Riprova.",
    "SOMETHING_GET_WRONG": "Upss.. Qualcosa è andato storto. Riprova.",
    "PAYMENT_CANCELLED": "Pagamento annullato.",
  },
  "fr": {
    "TOTAL": "Total",
    "IVA_INCLUDED": "TVA incluse",
    "MAKE_PURCHASE": "Faire l'achat",
    "MAKING_PURCHASE": "Autorisation...",
    "METHOD_NOT_SUPPORTED": "Méthode de paiement non prise en charge",
    "CUSTOMER_ABORT": "Processus Annulé",
    "INVALID_DATA": "Données non valides",
    "INVALID_ADDRESS": "Adresse non valide",
    "INVALID_CITY": "Ville non valide",
    "INVALID_NAME": "Nom non valide",
    "INVALID_POSTCODE": "Code Postal non valide",
    "INVALID_LASTNAME": "Nom de famille non valide",
    "INVALID_EMAIL": "Email non valide",
    "TRANSACTION_DECLINED": "Transaction Refusée",
    "UNKNOWN_ERROR": "Upss.. Une erreur inattendue s'est produite. Réessayer.",
    "SOMETHING_GET_WRONG": "Upss.. Quelque chose s'est mal passé. Réessayer.",
    "PAYMENT_CANCELLED": "Paiement annulé.",
  },
  "de": {
    "TOTAL": "Gesamt",
    "IVA_INCLUDED": "MwSt. enthalten",
    "MAKE_PURCHASE": "Kaufe",
    "MAKING_PURCHASE": "Autorisierung...",
    "METHOD_NOT_SUPPORTED": "Zahlungsmethode nicht unterstützt",
    "CUSTOMER_ABORT": "Prozess abgebrochen",
    "INVALID_DATA": "Ungültige Daten",
    "INVALID_ADDRESS": "Ungültige Adresse",
    "INVALID_CITY": "Ungültige Stadt",
    "INVALID_NAME": "Ungültiger Name",
    "INVALID_POSTCODE": "Ungültige Postleitzahl",
    "INVALID_LASTNAME": "Ungültiger Nachname",
    "INVALID_EMAIL": "Ungültige E-Mail",
    "TRANSACTION_DECLINED": "Transaktion abgelehnt",
    "UNKNOWN_ERROR": "Upss.. Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es erneut.",
    "SOMETHING_GET_WRONG": "Upss.. Etwas ist schief gelaufen. Versuchen Sie es erneut.",
    "PAYMENT_CANCELLED": "Zahlung abgebrochen.",
  },
  "pt": {
    "TOTAL": "Total",
    "IVA_INCLUDED": "IVA incluído",
    "MAKE_PURCHASE": "Fazer compra",
    "MAKING_PURCHASE": "Autorizando...",
    "METHOD_NOT_SUPPORTED": "Método de Pagamento não Suportado",
    "CUSTOMER_ABORT": "Processo Cancelado",
    "INVALID_DATA": "Dados Inválidos",
    "INVALID_ADDRESS": "Endereço Inválido",
    "INVALID_CITY": "Cidade Inválida",
    "INVALID_NAME": "Nome Inválido",
    "INVALID_POSTCODE": "Código Postal Inválido",
    "INVALID_LASTNAME": "Sobrenome Inválido",
    "INVALID_EMAIL": "Email Inválido",
    "TRANSACTION_DECLINED": "Transação Recusada",
    "UNKNOWN_ERROR": "Upss.. Ocorreu um erro inesperado. Tente novamente.",
    "SOMETHING_GET_WRONG": "Upss.. Algo deu errado. Tente novamente.",
    "PAYMENT_CANCELLED": "Pagamento cancelado.",
  },
}
</i18n>
<template>
  <div
    class="sticky bottom-0 w-full bg-white px-4 pt-4 shadow-inner mdplus:rounded-lg z-50"
  >
    <button
      v-if="mode === 'normal'"
      :disabled="!enableButton"
      @click="emit('confirm')"
      class="h-14 w-full tracking-wider rounded uppercase bg-others-black font-bold text-sm text-white transition-all duration-200 flex items-center justify-center gap-2"
      :style="'background-size: 200% 100%;'"
      :class="
        !enableButton
          ? 'opacity-50 cursor-not-allowed'
          : 'cursor-pointer hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s]'
      "
    >
      <img
        v-if="confirming"
        src="@/assets/svg/icons/icons-loading.svg"
        class="animate-spin h-6 w-6"
      />
      <span class="text-sm tracking-wider">{{
        confirming ? t('MAKING_PURCHASE') : t('MAKE_PURCHASE')
      }}</span>
    </button>
    <div
      v-else-if="mode === 'paypal'"
      id="paypal-buttons-container"
      class="flex gap-2"
      :class="
        !enableButton
          ? 'opacity-50 cursor-not-allowed pointer-events-none'
          : 'cursor-pointer'
      "
    ></div>
    <div
      v-else-if="['revolut', 'googlepay', 'applepay'].includes(mode)"
      id="revolut-button-container"
      ref="revolutButtonContainer"
      class="flex gap-2"
      :class="
        !enableButton
          ? 'opacity-50 cursor-not-allowed pointer-events-none'
          : 'cursor-pointer'
      "
    ></div>
  </div>
</template>

<script setup>
const props = defineProps({
  checkoutId: {
    type: String,
    required: false,
  },
  total: {
    type: Number,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
  enableButton: {
    type: Boolean,
    required: false,
  },
  status: {
    type: String,
    required: true,
  },
  mode: {
    type: String,
    required: false,
    default: 'normal',
    validator: (value) =>
      ['normal', 'paypal', 'revolut', 'applepay', 'googlepay'].includes(value),
  },
})

const emit = defineEmits(['confirm', 'completed', 'error'])

import { formatPrice } from '@/utils/helpers'
import RevolutCheckout from '@revolut/checkout'

import { usePayPal } from '@/composables/payments/paypal'
import { useRevolut } from '@/composables/payments/revolut'

const { t, locale } = useI18n()
const config = useRuntimeConfig()

const confirming = computed(() => props.status === 'CONFIRMING')
const paypalActions = ref({})
const enableButton = toRef(props, 'enableButton')
const revolutButtonContainer = ref()

// Usar watchEffect para reaccionar a cambios en paypalActions y enableButton
watchEffect(() => {
  if (props.mode == 'paypal') {
    if (!window?.paypal) return
    const FUNDING_SOURCES = [
      window.paypal.FUNDING.PAYPAL,
      window.paypal.FUNDING.PAYLATER,
    ]
    FUNDING_SOURCES.forEach((fundingSource) => {
      const actions = paypalActions.value[fundingSource]
      if (actions) {
        if (enableButton.value) {
          actions.enable()
        } else {
          actions.disable()
        }
      }
    })
  } else if (props.mode == 'revolut') {
    // TODO Habilito el button revolut
  }
})

const renderButton = () => {
  if (props.mode === 'paypal') {
    renderPaypalButtons()
  }
  if (props.mode === 'revolut') {
    renderRevolutButton()
  }
  if (props.mode === 'applepay') {
    renderGoogleOrApplePayButton('applePay')
  }
  if (props.mode === 'googlepay') {
    renderGoogleOrApplePayButton('googlePay')
  }
}

const renderPaypalButtons = () => {
  const { onLoaded } = usePayPal()
  onLoaded(() => {
    const FUNDING_SOURCES = [
      window.paypal.FUNDING.PAYPAL,
      window.paypal.FUNDING.PAYLATER,
    ]
    FUNDING_SOURCES.forEach(function (fundingSource) {
      var button = window.paypal.Buttons({
        fundingSource: fundingSource,
        style: {
          color: 'black',
          tagline: false,
          height: 50,
        },
        onInit(data, actions) {
          paypalActions.value[fundingSource] = actions
          if (!enableButton.value) {
            actions.disable()
          }
        },
        createOrder: async function () {
          try {
            const response = await $fetch(
              '/api/checkout/payments/paypal/create',
              {
                method: 'POST',
                body: {
                  amount: props.total,
                  currency: props.currency.code,
                },
              }
            )

            return response.id // Asumiendo que la respuesta contiene un ID de orden
          } catch (error) {
            console.error('Error al crear la orden de PayPal:', error)
            emit('error', error)
          }
        },
        onApprove: async function (data, actions) {
          try {
            const response = await $fetch(
              '/api/checkout/payments/paypal/capture',
              {
                method: 'POST',
                body: {
                  id: data.orderID,
                },
              }
            )

            emit('completed', response)
          } catch (error) {
            console.error('Error al crear la orden de PayPal:', error)
            emit('error', error)
          }
        },
        onCancel: function () {
          //onErrorCallback(createError({ message: 'CUSTOMER_ABORT' }))
        },
        onError: function (err) {
          console.debug('paypal onError')
          console.debug(err)
          emit('error', error)
        },
        // Add more PayPal button configuration here
      })

      if (button.isEligible()) {
        button.render('#paypal-buttons-container')
      }
    })
  })
}

const renderRevolutButton = async () => {
  const currency = props.currency.code
  const total = Math.round(props.total * 100)

  const { revolutPay } = await RevolutCheckout.payments({
    locale: locale.value, // Optional, defaults to 'auto'
    mode: config.public?.APP_ENV == 'production' ? 'prod' : 'sandbox',
    publicToken: config.public.REVOLUT_API_PUBLIC_KEY, // Merchant public API key
  })

  const paymentOptions = {
    currency: currency, // 3-letter currency code
    totalAmount: total, // in lowest denomination e.g., cents
    validate: () => {
      return enableButton.value
    },
    createOrder: async () => {
      // Call your backend here to create an order
      // For more information, see: https://developer.revolut.com/docs/merchant/create-order

      try {
        const response = await $fetch(
          '/api/checkout/payments/revolutpay/create',
          {
            method: 'POST',
            body: {
              checkoutId: props.checkoutId,
              amount: total,
              currency: currency,
            },
          }
        )

        return { publicId: response.token }
      } catch (error) {
        // TODO FRAN, ENVIAR A SENTRY
        console.error('Error al crear la orden de Revolut:', error)
        emit('error', error)
      }
    },
    buttonStyle: {
      radius: 'small',
      cashbackCurrency: currency,
    },
  }

  revolutPay.mount('#revolut-button-container', paymentOptions)

  revolutPay.on('payment', (event) => {
    if (event.type == 'success') {
      emit('completed')
      return
    }

    let error = {
      type: 'UNKNOWN',
      message: '',
    }
    switch (event.type) {
      case 'cancel': {
        error = {
          type: 'CANCEL',
          message: t('PAYMENT_CANCELLED'),
        }
        /*
        switch (event.dropOffState) {
          case 'payment_summary':
            error.message = 'what a shame, please complete your payment'
            break
          case 'enter_otp':
            error.message = 'Please enter the OTP to complete your payment'
            break
          case 'load_session_data':
            error.message = 'Loading session data...'
            break
          case 'enter_card_details':
            error.message = 'Please enter your card details'
            break
          case 'verify_user_details':
            error.message = 'Verifying user details...'
            break
          case 'enter_personal_details':
            error.message = 'Please enter your personal details'
            break
          case 'enter_shipping_details':
            error.message = 'Please enter your shipping details'
            break
          case 'revolut_app_push_challenge':
            error.message =
              'Please check your Revolut app to complete the challenge'
            break
          default:
            error.message = 'Payment cancelled'
        }*/
        break
      }
      case 'error':
        error = {
          type: event.error.type,
          message: event.error.message,
        }
        break
    }

    emit('error', error)
  })
}

const renderGoogleOrApplePayButton = async (mode) => {
  const total = Math.round(props.total * 100)
  const currency = props.currency.code

  const { paymentRequest } = await RevolutCheckout.payments({
    locale: locale.value, // Optional, defaults to 'auto'
    mode: config.public?.APP_ENV == 'production' ? 'prod' : 'sandbox',
    publicToken: config.public.REVOLUT_API_PUBLIC_KEY, // Merchant public API key
  })

  const paymentOptions = {
    preferredPaymentMethod: mode,
    currency: currency, // 3-letter currency code
    amount: total, // in lowest denomination e.g., cents
    validate: () => {
      return enableButton.value
    },
    createOrder: async () => {
      // Call your backend here to create an order
      // For more information, see: https://developer.revolut.com/docs/merchant/create-order

      try {
        const response = await $fetch(
          '/api/checkout/payments/revolutpay/create',
          {
            method: 'POST',
            body: {
              checkoutId: props.checkoutId,
              amount: total,
              currency: currency,
            },
          }
        )

        return { publicId: response.token }
      } catch (error) {
        // TODO FRAN, ENVIAR A SENTRY
        console.error('Error al crear la orden de Revolut:', error)
        emit('error', error)
      }
    },
    onSuccess() {
      emit('completed')
    },
    onError(error) {
      console.log(error)
      emit('error', error)
    },
    onCancel() {
      emit('error', {
        type: 'CANCEL',
        message: t('PAYMENT_CANCELLED'),
      })
    },
    buttonStyle: {
      radius: 'small',
    },
  }

  const instance = paymentRequest(revolutButtonContainer.value, paymentOptions)
  const method = await instance.canMakePayment()
  if (method) {
    instance.render()
  } else {
    // TODO PINTAR BOTON DE METODO NO SOPORTADO
    console.log('Apple Pay / Google Pay no disponibles...')
    instance.destroy()
  }
}

watch(
  () => props.mode,
  (newMode) => {
    renderButton()
  }
)

onMounted(async () => {
  renderButton()
})
</script>
