<i18n>
    {
      "es": {
          "ADD_PHONE_TITLE": "Añade tu teléfono para completar la compra",
          "ADD_PHONE_MESSAGE": "Tu número de teléfono es necesario para que podamos contactarte en caso de cualquier inconveniente con tu pedido.",
          "ADD_PHONE": "Añadir teléfono",
          "WARNING_ICON": "Icono de advertencia",
      },
      "en": {
        "ADD_PHONE_TITLE": "Add your phone to complete the purchase",
        "ADD_PHONE_MESSAGE": "Your phone number is required so we can contact you if there are any issues with your order.",
        "ADD_PHONE": "Add phone",
        "WARNING_ICON": "Warning icon",
      } ,
      "fr": {
        "ADD_PHONE_TITLE": "Ajoutez votre téléphone pour finaliser l'achat",
        "ADD_PHONE_MESSAGE": "Votre numéro de téléphone est nécessaire pour que nous puissions vous contacter en cas de problème avec votre commande.",
        "ADD_PHONE": "Ajouter un téléphone",
        "WARNING_ICON": "Icône d'avertissement",
      },
      "de": {
        "ADD_PHONE_TITLE": "Fügen Sie Ihr Telefon hinzu, um den Kauf abzuschließen",
        "ADD_PHONE_MESSAGE": "Ihre Telefonnummer ist erforderlich, damit wir Sie kontaktieren können, wenn es Probleme mit Ihrer Bestellung gibt.",
        "ADD_PHONE": "Telefon hinzufügen",
        "WARNING_ICON": "Warnsymbol",
      },
      "it": {
        "ADD_PHONE_TITLE": "Aggiungi il tuo telefono per completare l'acquisto",
        "ADD_PHONE_MESSAGE": "Il tuo numero di telefono è necessario per poterti contattare in caso di problemi con il tuo ordine.",
        "ADD_PHONE": "Aggiungi telefono",
        "WARNING_ICON": "Icona di avviso",
      },
      "pt": {
        "ADD_PHONE_TITLE": "Adicione seu telefone para completar a compra",
        "ADD_PHONE_MESSAGE": "Seu número de telefone é necessário para que possamos entrar em contato com você caso haja algum problema com seu pedido.",
        "ADD_PHONE": "Adicionar telefone",
        "WARNING_ICON": "Ícone de aviso",
      }
  }
  </i18n>

<template>
  <div class="mt-4 rounded bg-gray-100 p-2 pb-4">
    <div class="flex items-start space-x-2">
      <img
        src="@/assets/svg/icons/icons-warning.svg"
        :alt="t('WARNING_ICON')"
        class="w-6 h-6"
      />

      <p class="text-sm mt-1.5 text-sanremo-black font-semibold">
        {{ t('ADD_PHONE_TITLE') }}
      </p>
    </div>

    <p
      class="mt-1 mb-4 md:mb-5 text-xs text-gray-700 ml-8 leading-5 tracking-wide"
    >
      {{ t('ADD_PHONE_MESSAGE') }}
    </p>
    <button
      @click="$emit('showPhoneModal')"
      class="bg-sanremo-black text-white ml-8 rounded px-4 py-2 text-xs uppercase font-bold tracking-wider"
    >
      {{ t('ADD_PHONE') }}
    </button>
  </div>
</template>

<script setup>
const emit = defineEmits(['showPhoneModal'])

const { locale, t } = useI18n({
  useScope: 'local',
})
</script>
