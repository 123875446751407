<i18n>
  {
    "es": {
      "SAVE": 'Guardar',
      "SAVING": 'Guardando...',
      "APPLY": 'Aplicar',
      "APPLYING": "Aplicando...",
      "MY_COUPONS": "Mis cupones",
      "CANCEL": "Cancelar",

    },
    "en": {
      "SAVE": 'Save',
      "SAVING": 'Saving...',
      "APPLY": 'Apply',
      "APPLYING": "Applying...",
      "MY_COUPONS": "My coupons",
      "CANCEL": "Cancel",
    },
    "fr": {
      "SAVE": 'Enregistrer',
      "SAVING": 'Enregistrement...',
      "APPLY": 'Appliquer',
      "APPLYING": "Application...",
      "MY_COUPONS": "Mes coupons",
      "CANCEL": "Annuler",
    },
    "it": {
      "SAVE": 'Salva',
      "SAVING": 'Salvataggio...',
      "APPLY": 'Applica',
      "APPLYING": "Applicazione...",
      "MY_COUPONS": "I miei coupon",
      "CANCEL": "Annulla",
    },
    "de": {
      "SAVE": 'Speichern',
      "SAVING": 'Speichern...',
      "APPLY": 'Anwenden',
      "APPLYING": "Anwendung...",
      "MY_COUPONS": "Meine Gutscheine",
      "CANCEL": "Abbrechen",
    },
    "pt": {
      "SAVE": 'Salvar',
      "SAVING": 'Salvando...',
      "APPLY": 'Aplicar',
      "APPLYING": "Aplicando...",
      "MY_COUPONS": "Meus cupons",
      "CANCEL": "Cancelar",
    },
  }
</i18n>

<template>
  <ModalCourtain :visibility="visibility" @close="cancel">
    <template v-slot:title>
      <div class="flex flex-col items-center justify-center w-full bg-white">
        <h2 class="px-8 text-xl text-others-black text-center">
          {{ t('MY_COUPONS') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <div
        v-if="isLoading"
        class="relative isolate overflow-hidden before:border-t before:border-white/40 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/40 before:to-transparent"
      >
        <div class="space-y-10">
          <div class="w-full h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          <div class="w-full h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          <div class="w-full h-[60px] rounded-lg bg-[#d8d8d8]"></div>
          <div class="w-full h-[60px] rounded-lg bg-[#d8d8d8]"></div>
        </div>
      </div>

      <div
        v-else
        v-for="coupon in coupons"
        :key="coupon.id"
        class="flex justify-center items-center mt-4"
      >
        <div
          class="flex flex-col justify-between border-1 border-neutral-light-grey-2 rounded h-22 w-full p-4"
        >
          <div class="flex justify-between items-center w-full">
            <div class="flex flex-col w-full">
              <div class="flex justify-start items-center gap-x-2">
                <img
                  src="@/assets/svg/icons/icons-coupon.svg"
                  class="h-5 w-5"
                />
                <p class="text-sm">{{ coupon.code }}</p>
              </div>
              <p class="text-neutral-black-3 text-xs mt-2 leading-5">
                {{ coupon.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer
      ><div
        v-if="!isLoading"
        class="flex flex-col space-y-5 items-center justify-between"
      >
        <button
          @click="confirmDelete"
          :disabled="buttonEnabled"
          :aria-label="t('APPLY')"
          type="submit"
          class="uppercase w-full bg-others-black bg-opacity-90 text-white animation-get-light rounded h-14 font-bold text-sm transition-all duration-200 flex items-center justify-center"
          :class="buttonEnabled ? 'opacity-100' : 'opacity-50'"
        >
          <span>{{ t('APPLY') }}</span>
        </button>
        <button class="w-fit text-sm text-center" @click="cancel">
          {{ t('CANCEL') }}
        </button>
      </div></template
    >
  </ModalCourtain>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const props = defineProps({
  visibility: Boolean,
  coupons: Object,
  deleting: Boolean,
})

const { t } = useI18n()

const isLoading = ref(false)

const copyOfCoupons = props.coupons ? JSON.stringify(props.coupons) : null

const emit = defineEmits(['close', 'delete'])

const buttonEnabled = computed(() => {
  if (!copyOfCoupons) return false
  const parsedCopy = JSON.parse(copyOfCoupons)
  if (parsedCopy == props.coupons) return false
  return true
})

const cancel = () => {
  emit('close')
}

const confirmSave = () => {
  emit('save')
}
</script>
