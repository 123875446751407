export default function useCoD() {
  const implementation = {
    doAuthorize: (
      checkout,
      {
        onApprove: onApproveCallback,
        onChallenge: onChallengeCallback,
        onError: onErrorCallback,
      }
    ) => {
      console.debug('CoD doAuthorize....')
      onApproveCallback()
    },
  }

  return {
    implementation,
  }
}
