<i18n>
  {
    "es": {
        "DELIVERY_METHOD": "Método de entrega",
        "CHANGE": "Cambiar",
        "PICKUP": "Recogida",
        "PICKUP_POINT": "Selecciona un punto de recogida",
        "OTHER_PICKUP_POINT": "Selecciona otro punto de recogida",
        "ADD_ADDRESS": "Añadir dirección",
        "HOME_DELIVERY": "Envío a domicilio",
        "ESTIMATED_DELIVERY": "Entrega:",
        "ADDRESS_ADDED": "Dirección añadida correctamente", 
        "ADDRESS_EDITED": "Dirección editada correctamente",
        "ADDRESS_DELETED": "Dirección eliminada correctamente",
        "ERROR": "Ha ocurrido un error",
        "CLOCK_ICON": "Icono de reloj",
        "HOME_DELIVERY_ICON": "Icono de entrega en domicilio",
        "PICKUP_ICON": "Icono de recogida de pedido",
        "RIGHT_ARROW_ICON": "Icono de flecha a la derecha",
        "LEFT_ARROW_ICON": "Icono de flecha a la izquierda",
        "CHECK_ICON": "Icono de verificación",
        "STORE_PHOTO": "Foto de tienda",
        "ADD_ADDRESS_ICON": "Icono para añadir dirección",
        "ADD_PHONE_TITLE": "Añade tu teléfono para completar la compra",
        "ADD_PHONE_MESSAGE": "Tu número de teléfono es necesario para que podamos contactarte en caso de cualquier inconveniente con tu pedido.",
        "ADD_PHONE": "Añadir teléfono",
        "WARNING_ICON": "Icono de advertencia",
    },
    "en": {
      "DELIVERY_METHOD": "Delivery method",
      "CHANGE": "Change",
      "PICKUP": "Pickup",
      "PICKUP_POINT": "Select a pickup point",
      "OTHER_PICKUP_POINT": "Select another pickup point",
      "ADD_ADDRESS": "Add address",
      "HOME_DELIVERY": "Home delivery",
      "ESTIMATED_DELIVERY": "Estimated delivery:",
      "ADDRESS_ADDED": "Address added correctly",
      "ADDRESS_EDITED": "Address edited correctly",
      "ADDRESS_DELETED": "Address deleted correctly",
      "ERROR": "An error has occurred",
      "CLOCK_ICON": "Clock icon",
      "HOME_DELIVERY_ICON": "Home delivery icon",
      "PICKUP_ICON": "Pickup icon",
      "RIGHT_ARROW_ICON": "Right arrow icon",
      "LEFT_ARROW_ICON": "Left arrow icon",
      "CHECK_ICON": "Check icon",
      "STORE_PHOTO": "Store photo",
      "ADD_ADDRESS_ICON": "Add address icon",
      "ADD_PHONE_TITLE": "Add your phone to complete the purchase",
      "ADD_PHONE_MESSAGE": "Your phone number is required so we can contact you if there are any issues with your order.",
      "ADD_PHONE": "Add phone",
      "WARNING_ICON": "Warning icon",
    },
    "fr": {
      "DELIVERY_METHOD": "Méthode de livraison",
      "CHANGE": "Changer",
      "PICKUP": "Ramassage",
      "PICKUP_POINT": "Sélectionnez un point de collecte",
      "OTHER_PICKUP_POINT": "Sélectionnez un autre point de collecte",
      "ADD_ADDRESS": "Ajouter une adresse",
      "HOME_DELIVERY": "Livraison à domicile",
      "ESTIMATED_DELIVERY": "Livraison estimée :",
      "ADDRESS_ADDED": "Adresse ajoutée correctement",
      "ADDRESS_EDITED": "Adresse éditée correctement",
      "ADDRESS_DELETED": "Adresse supprimée correctement",
      "ERROR": "Une erreur s'est produite",
      "CLOCK_ICON": "Icône d'horloge",
      "HOME_DELIVERY_ICON": "Icône de livraison à domicile",
      "PICKUP_ICON": "Icône de ramassage",
      "RIGHT_ARROW_ICON": "Icône de flèche droite",
      "LEFT_ARROW_ICON": "Icône de flèche gauche",
      "CHECK_ICON": "Icône de vérification",
      "STORE_PHOTO": "Photo du magasin",
      "ADD_ADDRESS_ICON": "Icône d'ajout d'adresse",
      "ADD_PHONE_TITLE": "Ajoutez votre téléphone pour finaliser l'achat",
      "ADD_PHONE_MESSAGE": "Votre numéro de téléphone est requis pour que nous puissions vous contacter en cas de problème avec votre commande.",
      "ADD_PHONE": "Ajouter un téléphone",
      "WARNING_ICON": "Icône d'avertissement",
    },
    "de": {
      "DELIVERY_METHOD": "Liefermethode",
      "CHANGE": "Ändern",
      "PICKUP": "Abholung",
      "PICKUP_POINT": "Wählen Sie einen Abholpunkt aus",
      "OTHER_PICKUP_POINT": "Wählen Sie einen anderen Abholpunkt aus",
      "ADD_ADDRESS": "Adresse hinzufügen",
      "HOME_DELIVERY": "Lieferung nach Hause",
      "ESTIMATED_DELIVERY": "Voraussichtliche Lieferung:",
      "ADDRESS_ADDED": "Adresse korrekt hinzugefügt",
      "ADDRESS_EDITED": "Adresse korrekt bearbeitet",
      "ADDRESS_DELETED": "Adresse korrekt gelöscht",
      "ERROR": "Ein Fehler ist aufgetreten",
      "CLOCK_ICON": "Uhr-Symbol",
      "HOME_DELIVERY_ICON": "Symbol für Lieferung nach Hause",
      "PICKUP_ICON": "Symbol für Abholung",
      "RIGHT_ARROW_ICON": "Symbol für Pfeil nach rechts",
      "LEFT_ARROW_ICON": "Symbol für Pfeil nach links",
      "CHECK_ICON": "Kontrollsymbol",
      "STORE_PHOTO": "Fotogeschäft",
      "ADD_ADDRESS_ICON": "Symbol zum Hinzufügen von Adresse",
      "ADD_PHONE_TITLE": "Fügen Sie Ihr Telefon hinzu, um den Kauf abzuschließen",
      "ADD_PHONE_MESSAGE": "Ihre Telefonnummer ist erforderlich, damit wir Sie kontaktieren können, wenn es Probleme mit Ihrer Bestellung gibt.",
      "ADD_PHONE": "Telefon hinzufügen",
      "WARNING_ICON": "Warnsymbol",
    },
    "it": {
      "DELIVERY_METHOD": "Metodo di consegna",
      "CHANGE": "Cambia",
      "PICKUP": "Ritiro",
      "PICKUP_POINT": "Seleziona un punto di ritiro",
      "OTHER_PICKUP_POINT": "Seleziona un altro punto di ritiro",
      "ADD_ADDRESS": "Aggiungi indirizzo",
      "HOME_DELIVERY": "Consegna a domicilio",
      "ESTIMATED_DELIVERY": "Consegna stimata:",
      "ADDRESS_ADDED": "Indirizzo aggiunto correttamente",
      "ADDRESS_EDITED": "Indirizzo modificato correttamente",
      "ADDRESS_DELETED": "Indirizzo eliminato correttamente",
      "ERROR": "Si è verificato un errore",
      "CLOCK_ICON": "Icona dell'orologio",
      "HOME_DELIVERY_ICON": "Icona di consegna a domicilio",
      "PICKUP_ICON": "Icona di ritiro",
      "RIGHT_ARROW_ICON": "Icona freccia destra",
      "LEFT_ARROW_ICON": "Icona freccia sinistra",
      "CHECK_ICON": "Icona di verifica",
      "STORE_PHOTO": "Foto del negozio",
      "ADD_ADDRESS_ICON": "Icona per aggiungere l'indirizzo",
      "ADD_PHONE_TITLE": "Aggiungi il tuo telefono per completare l'acquisto",
      "ADD_PHONE_MESSAGE": "Il tuo numero di telefono è necessario per poterti contattare in caso di problemi con il tuo ordine.",
      "ADD_PHONE": "Aggiungi telefono",
      "WARNING_ICON": "Icona di avviso",
    },
    "pt": {
      "DELIVERY_METHOD": "Método de entrega",
      "CHANGE": "Alterar",
      "PICKUP": "Recolha",
      "PICKUP_POINT": "Selecione um ponto de recolha",
      "OTHER_PICKUP_POINT": "Selecione outro ponto de recolha",
      "ADD_ADDRESS": "Adicionar endereço",
      "HOME_DELIVERY": "Entrega em casa",
      "ESTIMATED_DELIVERY": "Entrega estimada:",
      "ADDRESS_ADDED": "Endereço adicionado corretamente",
      "ADDRESS_EDITED": "Endereço editado corretamente",
      "ADDRESS_DELETED": "Endereço eliminado corretamente",
      "ERROR": "Ocorreu um erro",
      "CLOCK_ICON": "Ícone de relógio",
      "HOME_DELIVERY_ICON": "Ícone de entrega em casa",
      "PICKUP_ICON": "Ícone de recolha",
      "RIGHT_ARROW_ICON": "Ícone de seta para a direita",
      "LEFT_ARROW_ICON": "Ícone de seta para a esquerda",
      "CHECK_ICON": "Ícone de verificação",
      "STORE_PHOTO": "Foto da loja",
      "ADD_ADDRESS_ICON": "Ícone de adicionar endereço",
      "ADD_PHONE_TITLE": "Adicione seu telefone para concluir a compra",
      "ADD_PHONE_MESSAGE": "Seu número de telefone é necessário para que possamos entrar em contato com você em caso de problemas com seu pedido.",
      "ADD_PHONE": "Adicionar telefone",
      "WARNING_ICON": "Ícone de aviso",
    }
}
</i18n>

<template>
  <div>
    <div class="flex justify-between items-center pt-10">
      <h2 class="flex justify-start text-base sm:text-base md:text-xl">
        {{ t('DELIVERY_METHOD') }}
      </h2>

      <div
        class="flex justify-start items-center gap-x-1 text-sanremo-gray text-sm"
      >
        <img
          src="@/assets/svg/icons/icons-clock-gray.svg"
          :alt="t('CLOCK_ICON')"
          class="h-4 w-4"
        />
        <p class="text-xxs md:text-sm">
          {{ t('ESTIMATED_DELIVERY') }} {{ eta }}
        </p>
      </div>
    </div>

    <div class="flex justify-start items-center gap-x-4 pt-5 md:pt-5 pb-6">
      <button
        v-for="deliveryMethod in deliveryMethods"
        :key="deliveryMethod.id"
        @click="toggleDeliveryMethod(deliveryMethod.type)"
        class="flex justify-center items-center gap-x-2 border-1 px-2 py-[1.12rem] md:px-4 md:py-4 w-6/12 md:w-4/12 rounded-lg"
        :class="
          selectedDeliveryMethodType === deliveryMethod.type
            ? ' border-sanremo-primaryblue border-2 bg-sanremo-primaryblue/5'
            : 'border-neutral-grey-4'
        "
      >
        <img
          v-if="deliveryMethod.type === 'HOME_DELIVERY_24H'"
          src="@/assets/svg/icons/icons-homedelivery.svg"
          :alt="t('HOME_DELIVERY_ICON')"
          class="h-4 w-4 md:h-5 md:w-5"
        />
        <img
          v-if="deliveryMethod.type === 'PICKUP_POINT'"
          src="@/assets/svg/icons/icons-pickup.svg"
          :alt="t('PICKUP_ICON')"
          class="h-4 w-4 md:h-5 md:w-5"
        />

        <p
          class="text-xs md:text-sm"
          :class="
            selectedDeliveryMethodType === deliveryMethod.type
              ? 'text-sanremo-black'
              : 'text-sanremo-gray'
          "
        >
          {{ deliveryMethod.title }}
        </p>
      </button>
    </div>

    <div v-if="selectedDeliveryMethodType === 'PICKUP_POINT'">
      <CheckoutDeliveryNoPhoneDisclaimer
        v-if="showPhoneDisclaimer"
        @showPhoneModal="emit('showPhoneModal')"
      />

      <div
        v-if="
          selectedDeliveryMethod.type == 'PICKUP_POINT' &&
          selectedDeliveryMethod.addresses.length === 0
        "
        class="w-full flex justify-start mt-5"
      >
        <button
          @click="showPickuppointsModal = true"
          class="text-left text-xxs tracking-wide md:tracking-wider underline underline-offset-2 mb-9"
        >
          {{ t('PICKUP_POINT') }}
        </button>
      </div>

      <div
        v-else-if="
          selectedDeliveryMethod.type == 'PICKUP_POINT' &&
          selectedDeliveryMethod.addresses.length > 0
        "
        class="flex flex-col gap-4"
      >
        <div
          v-for="pickup in selectedDeliveryMethod.addresses"
          :key="pickup.id"
          class="w-full md:w-1/2 mt-6 mb-4 flex rounded-md text-left items-center gap-3 border-1 border-neutral-black-2 p-4"
        >
          <button
            @click="openPickupInfoModal(pickup)"
            class="flex justify-start text-left items-center gap-x-4 w-full"
          >
            <img
              src="@/assets/img/dpd-store.jpg"
              :alt="t('STORE_PHOTO')"
              class="w-12 h-12"
            />
            <div class="flex flex-col">
              <p
                class="text-xs uppercase font-semibold text-sanremo-black tracking-wide"
              >
                {{ pickup?.name }}
              </p>
              <p class="text-sanremo-gray text-xs tracking-wide">
                {{ pickup?.address_street }}
              </p>
              <p class="text-sanremo-gray text-xs tracking-wide">
                {{ pickup?.address_city }}, {{ pickup?.address_postcode }}
              </p>
              <p class="text-xs tracking-wider">
                {{ pickup?.distance }}
              </p>
            </div>
          </button>
        </div>

        <div class="w-full flex justify-start">
          <button
            @click="showPickuppointsModal = true"
            class="text-left text-xxs tracking-wide md:tracking-wider underline underline-offset-2 -mt-1.5 mb-9"
          >
            {{ t('OTHER_PICKUP_POINT') }}
          </button>
        </div>
      </div>
    </div>

    <!-- HOME DELIVERY PART -->
    <div v-if="selectedDeliveryMethodType === 'HOME_DELIVERY_24H'">
      <div v-if="selectedDeliveryMethod.addresses">
        <div class="relative md:max-w-1400 md:mx-auto md:block">
          <div class="flex flex-col items-start overflow-hidden w-full">
            <div class="w-full mt-2 pb-8">
              <div
                @click="handleChangeLeft(false)"
                v-if="isDesktop && leftArrow && !hideArrows"
                class="h-10 w-10 grid place-items-center absolute top-0 bottom-5 -left-10 my-auto rounded-tr-md rounded-br-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
              >
                <img
                  src="@/assets/svg/icons/black/icons-left-arrow-light.svg"
                  class="h-8"
                  :alt="t('LEFT_ARROW_ICON')"
                />
              </div>
              <div
                @scroll="isDesktop ? null : handleScroll($event)"
                ref="sliderContainer"
                class="flex flex-nowrap space-x-1 mx-auto h-full py-0 pl-2 pr-0 md:pl-4 translate-x-0 transition-all duration-500 w-full snap-x snap-mandatory"
                :class="isDesktop ? '' : 'overflow-x-scroll scrollbar-hidden'"
              >
                <div
                  v-for="(address, index) in selectedDeliveryMethod.addresses"
                  :key="index"
                  class="cursor-pointer flex-shrink-0 py-4 px-2 md:px-0 overflow-y-hidden w-9/12 md:w-5/12"
                >
                  <CheckoutAddressCard
                    :key="address.id"
                    :address="address"
                    :addresses="addresses"
                    :addressIndex="index"
                    @click="
                      emit('updateAddress', {
                        methodId: selectedDeliveryMethod.id,
                        addressId: address.id,
                      })
                    "
                    @delete="confirmRemove(address.id)"
                    @edit="editAddress(address)"
                    :class="
                      selectedDeliveryMethod.addresses
                        ? 'rounded-lg border-neutral-black-2'
                        : 'border-neutral-grey-4'
                    "
                  />
                </div>
              </div>
              <div
                class="w-full flex justify-start"
                :class="
                  selectedDeliveryMethod.addresses.length > 0
                    ? 'mt-3 pl-4'
                    : 'pl-0 mt-3'
                "
              >
                <button
                  @click="openNewAddressModal"
                  class="text-left text-xxs mb-1 tracking-wide md:tracking-wider underline underline-offset-2"
                >
                  {{ t('ADD_ADDRESS') }}
                </button>
              </div>
              <div
                @click="handleChangeRight(true)"
                v-if="isDesktop && rightArrow && !hideArrows"
                class="h-10 w-10 grid place-items-center absolute top-0 bottom-5 -right-10 my-auto rounded-tl-md rounded-bl-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
              >
                <img
                  src="@/assets/svg/icons/black/icons-right-arrow-light.svg"
                  class="h-8"
                  :alt="t('RIGHT_ARROW_ICON')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ClientOnly>
      <LazyModalAddressConfirmDeleteCheckout
        :visibility="confirmDeleteModal"
        :address="address"
        :deleting="deleting"
        @delete="remove(address.id)"
        @close="confirmDeleteModal = false"
      />
    </ClientOnly>

    <ClientOnly>
      <LazyModalAddAddress
        :visibility="createAddress"
        :type="actionType"
        :address="addressForEdit"
        :saving="saving"
        @close="closeModalAddress"
        @newAddress="emit('refresh')"
        @editAddress="emit('refresh')"
        @formSubmit="handleAddressSubmit"
      />
    </ClientOnly>

    <ClientOnly>
      <ModalCheckoutPickuppointsContainer
        :sessionId="sessionId"
        :methods="deliveryMethods"
        :visibility="showPickuppointsModal"
        @close="showPickuppointsModal = false"
        @refresh="emit('refresh')"
      />
    </ClientOnly>

    <ModalCheckoutPickuppointsInfo
      v-if="selectedPickupPoint"
      :visibility="showPickupInfoModal"
      :selectedPickUpPoint="selectedPickupPoint"
      @close="showPickupInfoModal = false"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  sessionId: {
    type: String,
    required: true,
  },
  deliveryMethods: Array,
  showPhoneDisclaimer: Boolean,
  eta: String,
})

const { t } = useI18n({
  useScope: 'local',
})

const { isDesktop } = useDevice()
const { addMessage } = useSnackBar()
const address = ref({})
const confirmDeleteModal = ref(false)
const deleting = ref(false)
const sliderContainer = ref(null)
const leftArrow = ref(false)
const rightArrow = ref(true)
const createAddress = ref(false)
const actionType = ref('CREATE')
const addressForEdit = ref(null)
const saving = ref(false)
const addressSubmitUrl = ref('')
const formAddressSubmit = ref()
const requestBody = ref({})
const showPickuppointsModal = ref(false)
const showPickupInfoModal = ref(false)
const selectedPickupPoint = ref(null)
const addressId = ref(null)

const emit = defineEmits([
  'openNewAddressModal',
  'showPickupModal',
  'updateAddress',
  'refresh',
  'showPhoneModal',
])

const selectedDeliveryMethodType = computed(() => {
  return props.deliveryMethods.find(
    (deliveryMethod) => deliveryMethod.selected === true
  ).type
})

const selectedDeliveryMethod = computed(() => {
  return props.deliveryMethods.find(
    (deliveryMethod) => deliveryMethod.selected === true
  )
})

const openNewAddressModal = () => {
  actionType.value = 'CREATE'
  createAddress.value = true
}

const closeModalAddress = () => {
  createAddress.value = false
  actionType.value = ''
  addressForEdit.value = null
}

const openPickupInfoModal = (pickupPoint) => {
  selectedPickupPoint.value = pickupPoint
  showPickupInfoModal.value = true
}

const editAddress = (data) => {
  createAddress.value = true
  actionType.value = 'EDIT'
  addressForEdit.value = data
}

const confirmRemove = (id) => {
  const addressInAddresses = selectedDeliveryMethod.value.addresses.find(
    (address) => address.id === id
  )

  address.value = addressInAddresses
  confirmDeleteModal.value = true
}

const handleError = (error) => {
  console.debug(error)
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: t('ERROR'),
  })
}

const { execute: executeRemoveAddress, error: errorRemoveAddress } =
  await useAsyncData(
    'removeAddress',
    () => $fetch(`/api/account/addresses/${addressId.value}/delete`),
    { immediate: false }
  )

const remove = async (id) => {
  deleting.value = true

  addressId.value = id

  await executeRemoveAddress()

  if (errorRemoveAddress.value) {
    handleError(errorRemoveAddress.value)
    deleting.value = false
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('ADDRESS_DELETED'),
    })
    confirmDeleteModal.value = false
    deleting.value = false
    emit('refresh')
  }
}

const { execute: executeHandleAddressSubmit, error: errorAddressSubmit } =
  await useAsyncData(
    'handleAddressSubmit',
    () =>
      $fetch(addressSubmitUrl.value, {
        method: 'POST',
        body: requestBody.value,
      }),
    { immediate: false }
  )

const handleAddressSubmit = async (form) => {
  saving.value = true

  formAddressSubmit.value = form

  if (actionType.value === 'EDIT') {
    addressSubmitUrl.value = `/api/account/addresses/${addressForEdit.value.id}/update`
    requestBody.value = {
      changeDefault: false,
      address: formAddressSubmit.value,
    }
  }

  if (actionType.value === 'CREATE') {
    addressSubmitUrl.value = '/api/checkout/addresses/new'
    requestBody.value = {
      sessionId: props.sessionId,
      changeDefault: false,
      address: formAddressSubmit.value,
    }
  }

  await executeHandleAddressSubmit()

  if (errorAddressSubmit.value) {
    handleError(errorAddressSubmit.value)
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text:
        actionType.value === 'EDIT' ? t('ADDRESS_EDITED') : t('ADDRESS_ADDED'),
    })
    closeModalAddress()
    emit('refresh')
  }

  saving.value = false
}

const changeDeliveryMethod = ref(false)

const toggleDeliveryMethod = (option) => {
  changeDeliveryMethod.value = !changeDeliveryMethod.value
  props.deliveryMethods.forEach((deliveryMethod) => {
    if (deliveryMethod.type === option) {
      deliveryMethod.selected = true
    } else {
      deliveryMethod.selected = false
    }
  })
}

const addresses = ref([
  {
    id: 1,
    alias: 'Oficina',
    street: 'Calle Holanda, 5. Madrid 28971',
  },
  {
    id: 2,
    alias: 'Casa',
    street: 'Calle España, 7. Madrid 28971',
  },
  {
    id: 2,
    alias: 'Test',
    street: 'Calle Test, 7. Madrid 28971',
  },
])

const getCompanyLogo = (company) => {
  const logoPath = '/svg/delivery/'
  switch (company) {
    case 'mondial':
      return logoPath + 'dpd.svg'

    case 'glsspain':
      return logoPath + 'dpd.svg'

    case 'dpd':
      return logoPath + 'dpd.svg'

    default:
      return logoPath + 'dpd.svg'
  }
}

const maxScroll = computed(() => {
  if (!sliderContainer.value) return 0
  const child = sliderContainer.value.children[0]
  const childWidth = child.clientWidth
  return childWidth * sliderContainer.value.children.length + 1
})

const hideArrows = computed(() => {
  return selectedDeliveryMethod.value?.addresses.length <= 2
})

const handleScroll = (e) => {
  const containerWidth = sliderContainer.value.clientWidth
  const scrollWidth = sliderContainer.value.scrollWidth
  const scrollLeft = sliderContainer.value.scrollLeft

  actualTranslate.value = scrollLeft

  if (scrollLeft === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (scrollLeft + containerWidth >= scrollWidth - 20) {
    rightArrow.value = false
  } else {
    rightArrow.value = true
  }
}

const actualTranslate = ref(0)

const handleChangeRight = () => {
  const containerWidth = sliderContainer.value.clientWidth
  const childWidth = sliderContainer.value.children[0].clientWidth
  const extraMove = childWidth * 0.75
  const adjustMargin = 1

  const maxVisibleScroll = maxScroll.value - containerWidth

  const remainingScroll =
    maxScroll.value - actualTranslate.value - containerWidth

  if (remainingScroll > childWidth / 2) {
    sliderContainer.value.style.transform = `translateX(-${
      actualTranslate.value + childWidth + extraMove
    }px)`
    actualTranslate.value += childWidth + extraMove
  } else {
    sliderContainer.value.style.transform = `translateX(-${maxVisibleScroll}px)`
    actualTranslate.value = maxVisibleScroll
  }

  updateArrowsState()
}

const handleChangeLeft = () => {
  const containerWidth = sliderContainer.value.clientWidth
  const childWidth = sliderContainer.value.children[0].clientWidth
  const extraMove = childWidth * 0.75

  if (actualTranslate.value - (childWidth + extraMove) >= 0) {
    sliderContainer.value.style.transform = `translateX(-${
      actualTranslate.value - (childWidth + extraMove)
    }px)`
    actualTranslate.value -= childWidth + extraMove
  } else {
    sliderContainer.value.style.transform = `translateX(0px)`
    actualTranslate.value = 0
  }

  updateArrowsState()
}

const updateArrowsState = () => {
  const containerWidth = sliderContainer.value.clientWidth
  const scrollWidth = sliderContainer.value.scrollWidth
  const scrollLeft = actualTranslate.value

  leftArrow.value = scrollLeft > 0
  rightArrow.value = scrollLeft + containerWidth < scrollWidth - 20
}
</script>
