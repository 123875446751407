<i18n>
  {
    "es": {
      "BANK_TRANSFER_INFO": "Completa tu pedido con una transferencia bancaria. Realiza una transferencia a la cuenta proporcionada y comenzaremos a procesar tu pedido tan pronto como se confirme el pago.",
      "CREDIT_CARD_INFO": "Realiza un pago cómodo y seguro con tu tarjeta de crédito. Tus datos estarán protegidos en todo momento mientras procesamos tu transacción.",
      "BIZUM_INFO": "Paga de manera rápida y segura con Bizum. Sólo necesitas tu móvil para completar el pedido en cuestión de segundos, sin complicaciones.",
      "PAYPAL_INFO": "Paga de forma segura y rápida con PayPal. Tu cuenta estará protegida con su sistema de seguridad avanzada, permitiéndote un pago sin necesidad de compartir datos bancarios.",
      "REVOLUT_INFO": "Completa tu pago de manera eficiente y segura con Revolut. Disfruta de la facilidad de pago que ofrece con la seguridad de tus transacciones protegidas.",
      "COD_INFO": "Paga en efectivo al recibir tu pedido con el método contra reembolso. Este método está disponible para pedidos inferiores a 100€.",
      "GOOGLE_PAY_INFO": "Paga de forma rápida y segura con Google Pay. Disfruta de una transacción sin complicaciones utilizando tu cuenta de Google.",
      "APPLE_PAY_INFO": "Realiza tu pago de forma sencilla y segura con Apple Pay. Solo necesitas tu dispositivo Apple para completar la transacción."
    },
    "en": {
      "BANK_TRANSFER_INFO": "Complete your order with a bank transfer. Make a transfer to the provided account and we will begin processing your order as soon as the payment is confirmed.",
      "CREDIT_CARD_INFO": "Make a convenient and secure payment with your credit card. Your data will be protected at all times while we process your transaction.",
      "BIZUM_INFO": "Pay quickly and securely with Bizum. All you need is your phone to complete your order in seconds, hassle-free.",
      "PAYPAL_INFO": "Pay securely and quickly with PayPal. Your account will be protected by their advanced security system, allowing you to pay without sharing your bank details.",
      "REVOLUT_INFO": "Complete your payment efficiently and securely with Revolut. Enjoy the ease of payment this platform offers with the safety of protected transactions.",
      "COD_INFO": "Pay in cash upon delivery with the cash on delivery method. This option is available for orders under 100€.",
      "GOOGLE_PAY_INFO": "Pay quickly and securely with Google Pay. Enjoy a seamless transaction using your Google account.",
      "APPLE_PAY_INFO": "Make a simple and secure payment with Apple Pay. All you need is your Apple device to complete the transaction."
    },
    "fr": {
      "BANK_TRANSFER_INFO": "Finalisez votre commande par virement bancaire. Effectuez un virement sur le compte fourni et nous commencerons à traiter votre commande dès que le paiement sera confirmé.",
      "CREDIT_CARD_INFO": "Effectuez un paiement pratique et sécurisé avec votre carte de crédit. Vos données seront protégées en tout temps pendant que nous traitons votre transaction.",
      "BIZUM_INFO": "Payez rapidement et en toute sécurité avec Bizum. Vous n'avez besoin que de votre téléphone pour finaliser votre commande en quelques secondes, sans tracas.",
      "PAYPAL_INFO": "Payez de manière sécurisée et rapide avec PayPal. Votre compte sera protégé par leur système de sécurité avancé, vous permettant de payer sans partager vos coordonnées bancaires.",
      "REVOLUT_INFO": "Finalisez votre paiement de manière efficace et sécurisée avec Revolut. Profitez de la facilité de paiement offerte par cette plateforme avec la sécurité des transactions protégées.",
      "COD_INFO": "Payez en espèces à la livraison avec la méthode contre remboursement. Cette option est disponible pour les commandes de moins de 100€.",
      "GOOGLE_PAY_INFO": "Payez rapidement et en toute sécurité avec Google Pay. Profitez d'une transaction sans problème en utilisant votre compte Google.",
      "APPLE_PAY_INFO": "Effectuez un paiement simple et sécurisé avec Apple Pay. Vous n'avez besoin que de votre appareil Apple pour finaliser la transaction."
    },
    "it": {
      "BANK_TRANSFER_INFO": "Completa il tuo ordine con un bonifico bancario. Effettua un bonifico sul conto fornito e inizieremo a elaborare il tuo ordine non appena il pagamento sarà confermato.",
      "CREDIT_CARD_INFO": "Effettua un pagamento comodo e sicuro con la tua carta di credito. I tuoi dati saranno protetti in ogni momento mentre elaboriamo la tua transazione.",
      "BIZUM_INFO": "Paga in modo rapido e sicuro con Bizum. Ti basta il tuo telefono per completare l'ordine in pochi secondi, senza problemi.",
      "PAYPAL_INFO": "Paga in modo sicuro e veloce con PayPal. Il tuo account sarà protetto dal loro sistema di sicurezza avanzato, consentendoti di pagare senza condividere i tuoi dati bancari.",
      "REVOLUT_INFO": "Completa il tuo pagamento in modo efficiente e sicuro con Revolut. Goditi la facilità di pagamento offerta da questa piattaforma con la sicurezza delle transazioni protette.",
      "COD_INFO": "Paga in contanti alla consegna con il metodo in contrassegno. Questa opzione è disponibile per ordini inferiori a 100€.",
      "GOOGLE_PAY_INFO": "Paga in modo rapido e sicuro con Google Pay. Goditi una transazione senza problemi utilizzando il tuo account Google.",
      "APPLE_PAY_INFO": "Effettua un pagamento semplice e sicuro con Apple Pay. Ti basta il tuo dispositivo Apple per completare la transazione."
    },
    "de": {
      "BANK_TRANSFER_INFO": "Schließen Sie Ihre Bestellung mit einer Banküberweisung ab. Tätigen Sie eine Überweisung auf das angegebene Konto und wir beginnen mit der Bearbeitung Ihrer Bestellung, sobald die Zahlung bestätigt ist.",
      "CREDIT_CARD_INFO": "Tätigen Sie eine bequeme und sichere Zahlung mit Ihrer Kreditkarte. Ihre Daten werden jederzeit geschützt sein, während wir Ihre Transaktion bearbeiten.",
      "BIZUM_INFO": "Zahlen Sie schnell und sicher mit Bizum. Alles, was Sie brauchen, ist Ihr Telefon, um Ihre Bestellung in Sekundenschnelle und ohne Probleme abzuschließen.",
      "PAYPAL_INFO": "Zahlen Sie sicher und schnell mit PayPal. Ihr Konto wird durch ihr fortschrittliches Sicherheitssystem geschützt, das es Ihnen ermöglicht, ohne Weitergabe Ihrer Bankdaten zu zahlen.",
      "REVOLUT_INFO": "Schließen Sie Ihre Zahlung effizient und sicher mit Revolut ab. Genießen Sie die einfache Zahlungsmöglichkeit, die diese Plattform mit der Sicher der geschützten Transaktionen bietet.",
      "COD_INFO": "Zahlen Sie bar bei Lieferung mit der Nachnahme-Methode. Diese Option ist für Bestellungen unter 100€ verfügbar.",
      "GOOGLE_PAY_INFO": "Zahlen Sie schnell und sicher mit Google Pay. Genießen Sie eine reibungslose Transaktion, indem Sie Ihr Google-Konto verwenden.",
      "APPLE_PAY_INFO": "Tätigen Sie eine einfache und sichere Zahlung mit Apple Pay. Alles, was Sie brauchen, ist Ihr Apple Gerät, um die Transaktion abzuschließen."
    },
    "pt": {
      "BANK_TRANSFER_INFO": "Complete o seu pedido com uma transferência bancária. Faça uma transferência para a conta fornecida e começaremos a processar o seu pedido assim que o pagamento for confirmado.",
      "CREDIT_CARD_INFO": "Faça um pagamento conveniente e seguro com o seu cartão de crédito. Os seus dados estarão protegidos em todos os momentos enquanto processamos a sua transação.",
      "BIZUM_INFO": "Pague de forma rápida e segura com o Bizum. Tudo o que precisa é do seu telemóvel para completar a sua encomenda em segundos, sem complicações.",
      "PAYPAL_INFO": "Pague de forma segura e rápida com o PayPal. A sua conta estará protegida pelo seu sistema de segurança avançado, permitindo-lhe pagar sem partilhar os seus dados bancários.",
      "REVOLUT_INFO": "Complete o seu pagamento de forma eficiente e segura com o Revolut. Desfrute da facilidade de pagamento que esta plataforma oferece com a segurança das transações protegidas.",
      "COD_INFO": "Pague em dinheiro na entrega com o método de pagamento contra reembolso. Esta opção está disponível para encomendas inferiores a 100€.",
      "GOOGLE_PAY_INFO": "Pague de forma rápida e segura com o Google Pay. Desfrute de uma transação sem complicações utilizando a sua conta Google.",
      "APPLE_PAY_INFO": "Faça um pagamento simples e seguro com o Apple Pay. Tudo o que precisa é do seu dispositivo Apple para completar a transação."
    }
  }
</i18n>

<template>
  <ModalCourtainShippings :visibility="visibility" @close="cancel">
    <template v-slot:title>
      <div class="flex justify-between items-end w-full bg-white">
        <h2
          class="mx-auto md:mx-0 md:px-2 flex text-xl md:text-xl tracking-wider font-manrope font-bold text-sanremo-black text-center"
        >
          {{ methodInfo?.title }}
        </h2>
        <button v-if="!isMobileOrTablet" type="button" @click="cancel">
          <img src="@/assets/svg/icons/icons-close.svg" class="h-7 w-7" />
        </button>
      </div>
      <div>
        <div
          v-if="isMobileOrTablet"
          class="h-px w-full pl-96 -ml-2 bg-neutral-light-grey-1 -mt-2"
        ></div>
      </div>
    </template>
    <template v-slot:content>
      <div class="w-full">
        <div
          class="my-4 text-sanremo-black text-sm leading-6 tracking-wide text-center lg:text-left"
        >
          <!-- TODO This component should receive a dynamic property to display text for each payment method, mocked now  -->
          {{ methodInfoText }}
        </div>
      </div>
    </template>
  </ModalCourtainShippings>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  methodInfo: Object,
})

const { t } = useI18n({
  useScope: 'local',
})

const { isMobileOrTablet } = useDevice()

const emit = defineEmits(['close', 'delete'])

// TODO remove this computed property and receive the real property

const methodInfoText = computed(() => {
  switch (props.methodInfo?.method) {
    case 'Contra Reembolso':
      return t('COD_INFO')
    case 'Transferencia Bancaria':
      return t('BANK_TRANSFER_INFO')
    case 'creditcard':
      return t('CREDIT_CARD_INFO')
    case 'Bizum':
      return t('BIZUM_INFO')
    case 'PayPal':
      return t('PAYPAL_INFO')
    case 'Revolut':
      return t('REVOLUT_INFO')
    case 'Google Pay':
      return t('GOOGLE_PAY_INFO')
    case 'Apple Pay':
      return t('APPLE_PAY_INFO')
    default:
      return ''
  }
})

const cancel = () => {
  emit('close')
}
</script>
