<template>
  <div class="relative mt-4 md:max-w-1400 md:mx-auto md:block">
    <div class="flex flex-col items-start w-full">
      <div class="w-full mt-4 pb-4 md:pb-6">
        <div
          class="overflow-y-auto max-h-[340px] md:max-h-[400px]"
          style="
            scrollbar-width: thin;
            scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
            -webkit-scrollbar-width: thin;
          "
        >
          <div v-for="product in selected?.items" :key="product.id">
            <CheckoutProduct :product="product" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  shipments: {
    type: Array,
    required: true,
  },
  shipmentSelected: {
    type: String,
    required: true,
  },
})

const selected = computed(() => {
  return props.shipments?.find((shipment) => {
    return shipment.name === props.shipmentSelected
  })
})
</script>
