<i18n>
  {
    "es": {
      "FREE": "Gratis",
      "VAT_INCLUDED": "IVA incluido",
    },
    "en": {
      "FREE": "Free",
      "VAT_INCLUDED": "VAT included",
    },
    "fr": {
      "FREE": "Gratuit",
      "VAT_INCLUDED": "TVA incluse",
    },
    "it": {
      "FREE": "Gratuito",
      "VAT_INCLUDED": "IVA inclusa",
    },
    "de": {
      "FREE": "Kostenlos",
      "VAT_INCLUDED": "MwSt. enthalten",
    },
    "pt": {
      "FREE": "Grátis",
      "VAT_INCLUDED": "IVA incluído",
    },
  }
</i18n>

<template>
  <div class="flex flex-col items-start w-full tracking-wider mt-8">
    <div
      class="mb-6 flex items-center w-full justify-between text-sm font-light text-sanremo-black tracking-wider"
      v-for="total in totals"
      :key="total.type"
    >
      <span :class="total.type === 'total' ? 'font-semibold' : ''"
        >{{ total.title
        }}<span
          v-if="total.type === 'total'"
          class="ml-1 text-others-grey3 font-thin tracking-wider"
        >
          {{ t('VAT_INCLUDED') }}
        </span></span
      >

      <div v-if="total.type === 'shipping' && total.value === 0">
        <div class="space-x-1">
          <span class="text-sm line-through">
            {{ mockedShippingCost }}{{ currency.symbol }}
          </span>
          <span class="uppercase text-green-500 font-medium">
            {{ t('FREE') }}
          </span>
        </div>
      </div>

      <div v-else>
        <span :class="total.type === 'total' ? 'font-semibold' : ''">
          <span v-if="total.type === 'discount'">-</span
          >{{ formatPrice(total.value) }}{{ currency.symbol }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatPrice } from '@/utils/helpers'

const { t } = useI18n({
  useScope: 'local',
})

const props = defineProps({
  totals: {
    type: Array,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
})

const mockedShippingCost = ref('4,99')
</script>
