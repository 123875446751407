<template>
  <div>
    <div
      @click="confirmDeleteModal = true"
      class="flex justify-center items-center mt-8 w-fit"
    >
      <div
        class="flex justify-start items-center border-1 rounded h-22 w-full p-4"
        :class="
          coupon?.active
            ? 'border-sanremo-primaryblue'
            : 'border-neutral-light-grey-2'
        "
      >
        <div class="flex justify-between items-center">
          <div class="flex flex-col">
            <div class="flex justify-start items-center gap-x-2">
              <img src="@/assets/svg/icons/icons-coupon.svg" class="h-5 w-5" />
              <p class="text-sm">{{ coupon?.code }}</p>
            </div>
            <!-- <p
              class="text-neutral-black-3 text-xs w-full mt-2 tracking-wider leading-5 truncate"
            >
              {{ coupon?.description }}
            </p> -->
          </div>
        </div>
      </div>
    </div>

    <ModalCheckoutDeleteCoupon
      :visibility="confirmDeleteModal"
      :coupon="coupon"
      :deleting="deleting"
      @close="confirmDeleteModal = false"
      @delete="handleRemoveCoupon"
    />
  </div>
</template>

<script setup>
defineProps({
  coupon: Object,
  sessionId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['remove'])

const confirmDeleteModal = ref(false)
const deleting = ref(false)

const handleRemoveCoupon = async () => {
  deleting.value = true
  confirmDeleteModal.value = false

  emit('remove')
}
</script>
