<i18n>
  {
    "es": {
      "PHONE": "Teléfono:",
      "SCHEDULE": "Horario:",
      "SELECT": "Seleccionar",
      "MONDAY": "Lunes",
      "TUESDAY": "Martes",
      "WEDNESDAY": "Miércoles",
      "THURSDAY": "Jueves",
      "FRIDAY": "Viernes",
      "SATURDAY": "Sábado",
      "SUNDAY": "Domingo",
      "CLOSED": "Cerrado",
    },
    "en": {
      "PHONE": "Phone:",
      "SCHEDULE": "Opening Hours:",
      "SELECT": "Select",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday",
      "CLOSED": "Closed",
    },
    "fr": {
      "PHONE": "Téléphone:",
      "SCHEDULE": "Horaires d'ouverture:",
      "SELECT": "Sélectionner",
      "MONDAY": "Lundi",
      "TUESDAY": "Mardi",
      "WEDNESDAY": "Mercredi",
      "THURSDAY": "Jeudi",
      "FRIDAY": "Vendredi",
      "SATURDAY": "Samedi",
      "SUNDAY": "Dimanche",
      "CLOSED": "Fermé",
    },
    "it": {
      "PHONE": "Telefono:",
      "SCHEDULE": "Orari di apertura:",
      "SELECT": "Seleziona",
      "MONDAY": "Lunedì",
      "TUESDAY": "Martedì",
      "WEDNESDAY": "Mercoledì",
      "THURSDAY": "Giovedì",
      "FRIDAY": "Venerdì",
      "SATURDAY": "Sabato",
      "SUNDAY": "Domenica",
      "CLOSED": "Chiuso",
    },
    "de": {
      "PHONE": "Telefon:",
      "SCHEDULE": "Öffnungszeiten:",
      "SELECT": "Auswählen",
      "MONDAY": "Montag",
      "TUESDAY": "Dienstag",
      "WEDNESDAY": "Mittwoch",
      "THURSDAY": "Donnerstag",
      "FRIDAY": "Freitag",
      "SATURDAY": "Samstag",
      "SUNDAY": "Sonntag",
      "CLOSED": "Geschlossen",
    },
    "pt": {
      "PHONE": "Telefone:",
      "SCHEDULE": "Horário de funcionamento:",
      "SELECT": "Selecionar",
      "MONDAY": "Segunda-feira",
      "TUESDAY": "Terça-feira",
      "WEDNESDAY": "Quarta-feira",
      "THURSDAY": "Quinta-feira",
      "FRIDAY": "Sexta-feira",
      "SATURDAY": "Sábado",
      "SUNDAY": "Domingo",
      "CLOSED": "Fechado",
    },
  }
</i18n>

<template>
  <div class="w-full">
    <div id="map" class="flex items-center justify-center">
      <GoogleMap
        mapId="8ad31428e44912a"
        :api-promise="mapsApiPromise"
        :style="mapStyle"
        :center="center"
        :zoom="zoom"
        :disableDefaultUi="true"
        :mapTypeControlOptions="{
          mapTypeIds: [],
        }"
      >
        <Marker
          ref="markerRefs"
          v-for="(point, index) in pickUpPoints"
          :key="point.id"
          :options="{
            position: {
              lat: point.location.latitude,
              lng: point.location.longitude,
            },
            icon: point.selected ? selectedMarkerIcon : defaultMarkerIcon,
          }"
          @click="handleClick(point.id, index)"
        >
        </Marker>
      </GoogleMap>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  pickUpPoints: {
    type: Array,
    required: true,
  },
  center: {
    type: Object,
    required: true,
  },
  zoom: Number,
})

import { useI18n } from 'vue-i18n'
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map'
import { Loader } from '@googlemaps/js-api-loader'

const config = useRuntimeConfig()
const loader = new Loader({
  apiKey: config.public.GOOGLE_MAPS_API_KEY,
  version: 'weekly',
})

// Promise for a specific library
const mapsApiPromise = loader.load()

const { t } = useI18n()
const { isMobileOrTablet } = useDevice()

const emit = defineEmits(['select', 'openInfo', 'zoomToPickUpPoint'])

const markerRefs = ref([])
const infoWindowRefs = ref([])

onMounted(() => {
  const selectedPoint = props.pickUpPoints.find((point) => point.selected)
  if (selectedPoint) {
    emit('zoomToPickUpPoint', {
      lat: selectedPoint.location.latitude,
      lng: selectedPoint.location.longitude,
    })
  }
})

const defaultMarkerIcon = {
  path: 'M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.947 -2.055 M 12 9 m -3 0 a 3 3 0 1 0 6 0 a 3 3 0 1 0 -6 0 z',
  fillColor: '#FB65B6',
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
  anchor: new google.maps.Point(12, 22),
}

const selectedMarkerIcon = {
  path: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
  fillColor: '#1A1B1C',
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
  anchor: new google.maps.Point(12, 22),
}

const mapStyle = computed(() => {
  if (isMobileOrTablet) {
    return {
      width: '100vw',
      height: '70vh',
    }
  } else {
    return {
      width: '25vw',
      height: '70vh',
    }
  }
})

const handleClick = (id, index) => {
  const selectedPoint = props.pickUpPoints.find((point) => point.id === id)

  if (selectedPoint) {
    emit('openInfo', id)
    emit('zoomToPickUpPoint', {
      lat: selectedPoint.location.latitude,
      lng: selectedPoint.location.longitude,
    })
  }
}
</script>
