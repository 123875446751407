<i18n>
  {
    "es": {
      "SELECT": "Seleccionar",
      "SELECTED": "Seleccionado",
      "SAVING": "Guardando...",
      "MONDAY": "Lunes",
      "TUESDAY": "Martes",
      "WEDNESDAY": "Miércoles",
      "THURSDAY": "Jueves",
      "FRIDAY": "Viernes",
      "SATURDAY": "Sábado",
      "SUNDAY": "Domingo",
      "CLOSED": "Cerrado",
      "DAY": "Día",
      "TIME": "Hora",
      "PICKUPS": "Puntos de recogida",
      "CLOSE_ICON": "Icono para cerrar",
      "LOADING_ICON": "Icono cargando",
      "STORE_PHOTO": "Foto de la tienda",
      "COMPANY_LOGO": "Logo de la empresa de reparto",
      "SELECT_PICKUP": "Seleccionar punto de recogida"
    },
    "en": {
      "SELECT": "Select",
      "SELECTED": "Selected",
      "SAVING": "Saving...",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday",
      "CLOSED": "Closed",
      "DAY": "Day",
      "TIME": "Time",
      "PICKUPS": "Pickup points",
      "CLOSE_ICON": "Close icon",
      "LOADING_ICON": "Loading icon",
      "STORE_PHOTO": "Store photo",
      "COMPANY_LOGO": "Delivery company logo",
      "SELECT_PICKUP": "Select pickup point"
    },
    "fr": {
      "SELECT": "Sélectionner",
      "SELECTED": "Sélectionné",
      "SAVING": "Enregistrement...",
      "MONDAY": "Lundi",
      "TUESDAY": "Mardi",
      "WEDNESDAY": "Mercredi",
      "THURSDAY": "Jeudi",
      "FRIDAY": "Vendredi",
      "SATURDAY": "Samedi",
      "SUNDAY": "Dimanche",
      "CLOSED": "Fermé",
      "DAY": "Jour",
      "TIME": "Heure",
      "PICKUPS": "Points de collecte",
      "CLOSE_ICON": "Icône de fermeture",
      "LOADING_ICON": "Icône de chargement",
      "STORE_PHOTO": "Photo du magasin",
      "COMPANY_LOGO": "Logo de l'entreprise de livraison",
      "SELECT_PICKUP": "Sélectionner un point de collecte"
    },
    "it": {
      "SELECT": "Seleziona",
      "SELECTED": "Selezionato",
      "SAVING": "Salvataggio...",
      "MONDAY": "Lunedì",
      "TUESDAY": "Martedì",
      "WEDNESDAY": "Mercoledì",
      "THURSDAY": "Giovedì",
      "FRIDAY": "Venerdì",
      "SATURDAY": "Sabato",
      "SUNDAY": "Domenica",
      "CLOSED": "Chiuso",
      "DAY": "Giorno",
      "TIME": "Ora",
      "PICKUPS": "Punti di ritiro",
      "CLOSE_ICON": "Icona di chiusura",
      "LOADING_ICON": "Icona di caricamento",
      "STORE_PHOTO": "Foto del negozio",
      "COMPANY_LOGO": "Logo dell'azienda di consegna",
      "SELECT_PICKUP": "Seleziona il punto di ritiro"
    },
    "de": {
      "SELECT": "Auswählen",
      "SELECTED": "Ausgewählt",
      "SAVING": "Speichern...",
      "MONDAY": "Montag",
      "TUESDAY": "Dienstag",
      "WEDNESDAY": "Mittwoch",
      "THURSDAY": "Donnerstag",
      "FRIDAY": "Freitag",
      "SATURDAY": "Samstag",
      "SUNDAY": "Sonntag",
      "CLOSED": "Geschlossen",
      "DAY": "Tag",
      "TIME": "Zeit",
      "PICKUPS": "Abholpunkte",
      "CLOSE_ICON": "Schließen-Symbol",
      "LOADING_ICON": "Lade-Symbol",
      "STORE_PHOTO": "Ladenfoto",
      "COMPANY_LOGO": "Lieferunternehmen-Logo",
      "SELECT_PICKUP": "Abholpunkt auswählen"
    },
    "pt": {
      "SELECT": "Selecionar",
      "SELECTED": "Selecionado",
      "SAVING": "Salvando...",
      "MONDAY": "Segunda-feira",
      "TUESDAY": "Terça-feira",
      "WEDNESDAY": "Quarta-feira",
      "THURSDAY": "Quinta-feira",
      "FRIDAY": "Sexta-feira",
      "SATURDAY": "Sábado",
      "SUNDAY": "Domingo",
      "CLOSED": "Fechado",
      "DAY": "Dia",
      "TIME": "Hora",
      "PICKUPS": "Pontos de recolha",
      "CLOSE_ICON": "Ícone de fechar",
      "LOADING_ICON": "Ícone de carregamento",
      "STORE_PHOTO": "Foto da loja",
      "COMPANY_LOGO": "Logotipo da empresa de entrega",
      "SELECT_PICKUP": "Selecionar ponto de recolha"
    }
  }
</i18n>

<template>
  <div class="h-full w-full md:p-4">
    <div
      v-if="!isMobileOrTablet"
      class="flex justify-end sticky top-0 bg-white z-10 py-4"
    >
      <button @click="emit('closeList')" type="button">
        <img
          src="@/assets/svg/icons/icons-close.svg"
          :alt="t('CLOSE_ICON')"
          class="h-6 w-6"
        />
      </button>
    </div>

    <div class="md:overflow-y-auto max-h-[calc(100vh-80px)] mt-0 md:mt-2 pb-8">
      <div
        v-for="pickUpPoint in pickUpPoints"
        :key="pickUpPoint.id"
        class="flex flex-col w-full items-center mb-4"
      >
        <div
          class="flex flex-col border rounded h-auto w-full p-4"
          :class="{
            'bg-neutral-light-grey-2': pickUpPoint.selected,
            'border-black':
              !pickUpPoint.selected && pickUpPoint === selectedPickupPoint,
            'border-neutral-light-grey-2':
              !pickUpPoint.selected && pickUpPoint !== selectedPickupPoint,
          }"
        >
          <div class="flex items-center">
            <button
              @click="selectPickUpPoint(pickUpPoint)"
              :aria-label="t('SELECT_PICKUP')"
              type="button"
              class="rounded-full w-6 h-6 min-w-[1.5rem] min-h-[1.5rem] border fill-transparent border-neutral-grey-1 relative cursor-pointer grid place-items-center"
              :class="{
                'bg-black':
                  pickUpPoint.selected || pickUpPoint === selectedPickupPoint,
                'hover:border-black': pickUpPoint !== selectedPickupPoint,
              }"
            >
              <img
                v-if="pickUpPoint.selected"
                src="@/assets/svg/icons/icons-check-white.svg"
                :alt="t('CLOSE_ICON')"
                class="h-4 w-4"
              />

              <div
                v-else-if="
                  pickUpPoint === selectedPickupPoint && !pickUpPoint.selected
                "
                class="bg-white rounded-full w-2.5 h-2.5"
              ></div>
            </button>

            <div class="flex flex-col ml-4">
              <p
                class="text-xs md:text-sm uppercase font-semibold text-left tracking-wide leading-5 pr-1 md:pr-4"
              >
                {{ pickUpPoint.name }}
              </p>
              <p
                class="text-xxs md:text-xs mt-1 tracking-wider leading-5 md:pr-4 text-left text-sanremo-gray"
              >
                {{ pickUpPoint.address.address_street }}
              </p>
            </div>

            <div class="flex flex-col items-end ml-auto">
              <img
                :src="pickUpPoint.icon"
                :alt="t('COMPANY_LOGO')"
                class="w-12 mb-2"
              />
              <div class="text-sm tracking-wider whitespace-nowrap">
                {{ pickUpPoint.distance }}
              </div>
            </div>
          </div>

          <transition name="fade">
            <div
              v-if="
                selectedPickupPoint && selectedPickupPoint.id === pickUpPoint.id
              "
              class="w-full mt-4"
            >
              <div class="flex justify-start items-center gap-x-4">
                <img
                  src="@/assets/img/dpd-store.jpg"
                  :alt="t('STORE_PHOTO')"
                  class="w-20 h-20"
                />
                <div class="flex flex-col">
                  <img
                    :src="selectedPickupPoint.icon"
                    :alt="t('COMPANY_LOGO')"
                    class="h-10 w-16"
                  />
                  <p class="text-xs uppercase font-semibold tracking-wide">
                    {{ selectedPickupPoint.name }}
                  </p>
                  <p class="text-sanremo-gray text-xs tracking-wider">
                    {{ selectedPickupPoint.address.address_street }}
                  </p>
                  <p class="text-xs">{{ selectedPickupPoint.distance }}</p>
                </div>
              </div>

              <table
                class="mt-5 w-full bg-white border border-neutral-light-grey-1 border-collapse text-sm text-neutral-black-3"
                :class="pickUpPoint.selected ? 'mb-2' : ''"
              >
                <thead>
                  <tr>
                    <th
                      class="py-3 px-6 border-b border-r text-start tracking-wide text-black font-bold"
                    >
                      {{ t('DAY') }}
                    </th>
                    <th
                      class="py-3 px-6 border-b text-start tracking-wide text-black font-bold"
                    >
                      {{ t('TIME') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="time in selectedPickupPoint.info.timetable"
                    :key="time.day"
                  >
                    <td class="py-3 px-6 border-b border-r text-start">
                      {{ t(time.day) }}
                    </td>
                    <td
                      class="py-3 px-6 border-b text-start"
                      v-if="!time.fullDay && (time.morning || time.afternoon)"
                    >
                      {{ time.morning }} | {{ time.afternoon }}
                    </td>
                    <td
                      class="py-3 px-6 border-b text-start"
                      v-else-if="time.fullDay"
                    >
                      {{ time.fullDay }}
                    </td>
                    <td class="py-3 px-6 border-b text-start" v-else>
                      {{ t('CLOSED') }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="mt-4" v-if="!pickUpPoint.selected">
                <button
                  :disabled="saving"
                  @click="confirmPickUpPoint"
                  class="flex items-center justify-center mx-auto bg-black h-12 text-white rounded text-xs uppercase font-bold tracking-[1px] bg-opacity-90 w-full mdplus:mx-auto hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s] transition-all duration-200 hover:cursor-pointer"
                  style="background-size: 200% 100%"
                >
                  <div class="flex items-center justify-center space-x-2">
                    <img
                      v-if="saving"
                      src="@/assets/svg/icons/icons-loading.svg"
                      :alt="t('LOADING_ICON')"
                      class="animate-spin h-4 w-4"
                    />
                    <span>{{ saving ? t('SAVING') : t('SELECT') }}</span>
                  </div>
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  pickUpPoints: {
    type: Array,
    required: true,
  },
  saving: Boolean,
})

const selectedPickupPoint = ref(null)
const emit = defineEmits(['select', 'closeList', 'zoomToPickUpPoint'])
const { t } = useI18n({ useScope: 'local' })
const { isMobileOrTablet } = useDevice()

onMounted(() => {
  const alreadySelectedPoint = props.pickUpPoints.find(
    (point) => point.selected
  )
  if (alreadySelectedPoint) {
    selectedPickupPoint.value = alreadySelectedPoint
  }
})

const selectPickUpPoint = (pickUpPoint) => {
  selectedPickupPoint.value = pickUpPoint

  emit('zoomToPickUpPoint', {
    lat: pickUpPoint.location.latitude,
    lng: pickUpPoint.location.longitude,
  })
}

const confirmPickUpPoint = () => {
  if (selectedPickupPoint.value) {
    emit('select', selectedPickupPoint.value.id)
  }
}
</script>
