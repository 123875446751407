<i18n>
    {
      "es": {
        "MAX_AMOUNT_INFORMATION": "Válido en pedidos inferiores a ",
      },
      "en": {
        "MAX_AMOUNT_INFORMATION": "Valid for orders below",
      }
    }
</i18n>

<template>
  <div class="w-full">
    <div
      :class="collapse ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'"
      class="overflow-hidden transition-all duration-300 ease-in-out"
    >
      <div class="w-full mt-6 mb-4">
        <div
          v-for="method in paymentMethods"
          :key="method.id"
          class="mt-4 w-full"
        >
          <button
            @click="emit('selectPaymentMethod', method?.id)"
            class="flex justify-between items-center text-sanremo-black border rounded-lg w-full px-4 py-2.5 h-16"
            :class="[
              method.selected ? 'border-black' : '',
              method.max_amount < total ? 'opacity-50 cursor-not-allowed' : '',
            ]"
            :disabled="method.max_amount < total || method.selected"
          >
            <div class="w-full flex items-center gap-x-4">
              <img :src="method.img.src" :alt="method.title" class="w-12 h-8" />
              <div class="flex-grow flex flex-col items-start space-y-1">
                <div class="flex items-center space-x-1">
                  <p class="text-xxs tracking-wider font-medium capitalize">
                    {{ method.title }}
                  </p>
                  <p v-if="method.cost > 0" class="text-xxs text-sanremo-gray">
                    (+{{ method.cost }}{{ currency }})
                  </p>
                  <button
                    type="button"
                    @click.stop="emit('openPaymentInfoModal', method)"
                    aria-label="paymentmodal"
                    :class="
                      method?.subtitle || method?.disclaimer ? '' : 'mt-0.5'
                    "
                  >
                    <img
                      src="@/assets/svg/icons/icons-info.svg"
                      class="w-3 h-3"
                      alt="Información"
                    />
                  </button>
                </div>
                <p
                  v-if="method?.subtitle"
                  class="text-xxs text-sanremo-gray tracking-wider"
                >
                  {{ method?.subtitle }} {{ method?.max_amount }} {{ currency }}
                </p>
                <p
                  v-else-if="method?.disclaimer"
                  class="text-xxs text-sanremo-gray tracking-wider"
                >
                  {{ method?.disclaimer }}
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  collapse: {
    type: Boolean,
    required: true,
  },
  paymentMethods: {
    type: Array,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
})

const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['selectPaymentMethod', 'openPaymentInfoModal'])
</script>
