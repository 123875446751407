export function usePayPal() {
  const config = useRuntimeConfig()

  const environment = import.meta.dev ? 'sandbox' : 'www'

  const { onLoaded } = useScript(
    {
      src: `https://${environment}.paypal.com/sdk/js?client-id=${config.public.PAYPAL_CLIENT_ID}&components=buttons,marks,messages&locale=es_ES&currency=EUR&intent=capture&enable-funding=paylater`,
      referrerpolicy: false,
      crossorigin: false,
    },
    {
      use() {
        return window.paypal
      },
    }
  )

  const implementation = {
    async doAuthorize() {
      throw createError({
        statusCode: 500,
        statusMessage: 'PayPal Authorization Failed',
        message: 'An error occurred while authorizing the PayPal payment.',
      })
    },
  }

  return {
    onLoaded,
    implementation,
  }
}
