export function useRevolut() {
  const config = useRuntimeConfig()

  const implementation = {
    async doAuthorize() {
      throw createError({
        statusCode: 500,
        statusMessage: 'Revolut Authorization Failed',
        message: 'An error occurred while authorizing the revolut payment.',
      })
    },
  }

  return {
    implementation,
  }
}
